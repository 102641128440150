import React, { useEffect, useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import UpdateModal from "../modals/UpdateModal";
import DeleteModal from "../modals/DeleteModal";
import AddModal from "../modals/AddModal";
import {
  setAddModalOpen,
  setDeleteModalOpen,
  setUpdateModalOpen,
} from "../redux/modalSlice";
import AdministratorTable from "../components/tables/AdministratorTable";
import { CiSearch } from "react-icons/ci";
import { useAuth } from "../providers/AuthProvider";
import { useTranslation } from "react-i18next";
const Administrator = () => {
  const { t } = useTranslation();
  const updateModalOpen = useSelector((state) => state.modals.updateModalOpen);
  const deleteModalOpen = useSelector((state) => state.modals.deleteModalOpen);
  const addModalOpen = useSelector((state) => state.modals.addModalOpen);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const { token,company,userRole } = useAuth();

  const requestSearch = (searchInput) => {
    const filteredRows = users.filter((row) => {
      return row.name
        .toLowerCase()
        .includes(searchInput.target.value.toLowerCase());
    });
    setUsers(filteredRows);
  };

  const fetchData = async () => {


    try {
      const response = await fetch(
        `https://dashboard.thermosoft.com.tr/api/users/bycompanyid?companyId=${company}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("HTTP error " + response.status);
      }

      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.log("Fetch error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [updateModalOpen,deleteModalOpen,addModalOpen,company]);

  return (
    <div className="flex flex-col overflow-auto w-full h-full gap-3">
      <div className="flex flex-row  items-center justify-between overflow-hidden w-full h-9 md:h-10 p-1">
        <p className="text-sm md:text-lg text-text-color font-semibold">
          {t("administrator.users")}
        </p>

        <div className="flex flex-row items-center justify-between gap-2">
          <div className="h-7 sm:h-9 w-40 sm:w-[400px] dark:bg-component-color bg-white rounded-md flex flex-row justify-between  border border-border-color  ">
            <input
              placeholder="Enter name"
              type="text"
              onChange={requestSearch}
              className="dark:bg-component-color bg-white rounded-l-md outline-none w-2/3 sm:w-[366px] h-full px-2 text-xs md:text-base text-text-color"
            />
            <div className="md:w-[34px] h-full flex rounded-md items-center justify-center">
              <CiSearch
                className="text-gray-400 scale-75 md:scale-100"
                size={24}
              />
            </div>
          </div>

          {/*      <div onClick={setAddModal} className='bg-logo-color bg-opacity-10 flex flex-row items-center gap-2 rounded-full p-1.5 cursor-pointer hover:scale-110'>
            <IoAddOutline size={22} className='text-logo-color' />
          </div> */}

          <button
            onClick={() => dispatch(setAddModalOpen(true))}
            className="h-6 sm:h-9 w-[36px] md:w-[120px] bg-logo-color text-black rounded-md flex flex-row items-center justify-center gap-2 font-semibold cursor-pointer "
          >
            <p className="hidden md:block">{t("administrator.add-user")}</p>
            <IoAddOutline
              size={22}
              className=" text-black scale-75 sm:scale-100"
            />
          </button>
        </div>
      </div>

      <div className="flex flex-1 overflow-hidden">
        <AdministratorTable rows={users} />
      </div>

      {updateModalOpen && <UpdateModal />}
      {deleteModalOpen && <DeleteModal />}
      {addModalOpen && <AddModal />}
    </div>
  );
};

export default Administrator;
