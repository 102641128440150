import React, { useCallback, useEffect, useRef, useState } from "react";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { IoIosClose, IoIosPlay } from "react-icons/io";
import { BsExclamationCircle } from "react-icons/bs";
import { IoClose, IoPlay, IoPlayForwardCircleSharp } from "react-icons/io5";
import PredictionTable from "../components/tables/PredictionTable";
import NotificationTable from "../components/tables/NotificationTable";
import { useAuth } from "../providers/AuthProvider";
import photoMap from "../utils/santrals";
import positions from "../utils/positions";
import Draggable from "react-draggable";
import DateTimePicker from "../components/selectBoxes/DateTimePicker";
import { now } from "moment/moment";


const Orc = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [notificationModal, setNotificationModal] = useState(false);

  const [data, setData] = useState({});
  const [modalTitle, setModalTitle] = useState("")
  const [datas, setDatas] = useState([]);
  const [boxData, setBoxData] = useState({});
  const { token, company } = useAuth();
  const [calculatedValues, setCalculatedValues] = useState({})
  const [selectedDate, setSelectedDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());

  const fetchOrcDataByDateTime = async (datetime) => {
    try {
      const response = await fetch(
        `https://dashboard.thermosoft.com.tr/api/santral/getLastValuesWithExtraData?datetime=${datetime}`, {
        credentials: "include"
      }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const json = await response.json();
      setDatas(json);
    } catch (error) {
      console.error("ORC verisi yüklenirken bir hata oluştu:", error);
    }
  };


  const fetchCalculatedOrcByDateTime = async (datetime) => {
    try {
      const response = await fetch(
        `https://dashboard.thermosoft.com.tr/api/calculatedorc/getCalculatedOrcByDateTime?datetime=${datetime}`,
        {
          credentials: "include"
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const json = await response.json();
      setCalculatedValues(json);
    } catch (error) {
      console.error("CalculatedOrc verisi yüklenirken bir hata oluştu:", error);
    }
  };

  const handleDateChange = (date) => {
    if (!date) {
      setSelectedDate(null);
      return;
    }
    setSelectedDate(date);
    const formattedDate = date.toISOString();
    fetchOrcDataByDateTime(formattedDate);
    fetchCalculatedOrcByDateTime(formattedDate);
  };

  const handleTodayClick = () => {
    setSelectedDate(null);
    setStartDate(new Date())
  };


  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault();
    }

    const rootElement = document.getElementById('wrapper');
    rootElement.addEventListener('contextmenu', handleContextMenu);

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);




  useEffect(() => {
    window.addEventListener("theme", () => {
      setTheme(localStorage.getItem("theme"));
    });
  }, []);

  const divRef = useRef();

  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: div } = divRef;

    if (div) {
      const value = make3dTransformValue({ x, y, scale });

      div.style.setProperty("transform", value);
    }
  }, []);

  const onClick = (value, modalTitle) => {

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://dashboard.thermosoft.com.tr/api/calculatedorc/${value}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        setData(json);
      } catch (error) {
        setData(null)
        console.error("Veri yüklenirken bir hata oluştu:", error);
      }
    };

    fetchData();
    setModalTitle(modalTitle);
    setIsOpen(true);
  };

  const renderDataModal = () => {
    return Object.keys(data).map((key, index) => {
      const { value, unit } = data[key];

      const displayValue = key === "R"
        ? (function () {
          const scientificValue = parseFloat(value).toExponential(2);
          const [mantissa, exponent] = scientificValue.split("e");
          return (
            <>
              {parseFloat(mantissa).toFixed(2)}e<sup>{exponent}</sup>
            </>
          );
        })()
        : parseFloat(value).toFixed(2);

      return (
        <div key={index} className="flex flex-row items-center justify-between p-3 border-b-2 border-border-color">
          <p className="text-center w-1/3 overflow-hidden text-ellipsis whitespace-nowrap">{key}</p>
          <p className="text-center w-1/3 overflow-hidden text-ellipsis whitespace-nowrap">{displayValue}</p>
          <p className="w-1/3 text-center overflow-hidden text-ellipsis whitespace-nowrap">{unit}</p>
        </div>
      );
    });
  };





  const elements = [
    {
      id: "fan-left",
      className: "absolute left-0 top-[60px] w-[750px] h-[310px] cursor-pointer",
      onClick: () => onClick("hpcondenser", "HP CONDENSER")
    },
    {
      id: "fan-right",
      className: "absolute right-0 top-[60px] w-[750px] h-[310px] cursor-pointer",
      onClick: () => onClick("lpcondenser", "LP CONDENSER")
    },
    {
      id: "hpturbine",
      className: "absolute left-[1416px] top-[285px] w-[496px] h-[271px] cursor-pointer",
      onClick: () => onClick("hpturbine", "HP Turbine")
    },
    {
      id: "lpturbine",
      className: "absolute left-[1920px] top-[285px] w-[496px] h-[271px] cursor-pointer",
      onClick: () => onClick("lpturbine", "LP Turbine")
    },
    {
      id: "hp-evaporator",
      className: "absolute left-[820px] top-[665px] w-[505px] h-[170px] cursor-pointer",
      onClick: () => onClick("hpevoperator", "HP EVAPORATOR")
    },
    {
      id: "lp-evaporator",
      className: "absolute left-[2498px] top-[676px] w-[505px] h-[170px] cursor-pointer",
      onClick: () => onClick("lpevoperator", "LP EVAPORATOR")
    },
    {
      id: "hp-preheater-1",
      className: "absolute left-[825px] top-[1004px] w-[495px] h-[137px] cursor-pointer",
      onClick: () => onClick("PH1002", "PH1002")
    },
    {
      id: "hp-preheater-2",
      className: "absolute left-[831px] top-[1469px] w-[495px] h-[250px] cursor-pointer",
      onClick: () => onClick("PH1001AB", "1001AB")
    },
    {
      id: "lp-preheater-2",
      className: "absolute left-[2503px] top-[1469px] w-[495px] h-[250px] cursor-pointer",
      onClick: () => onClick("PH2001AB", "2001AB")
    },
    {
      id: "condenser",
      className: "absolute left-[1827px] top-[794px] w-[179px] h-[337px] cursor-pointer",
      onClick: () => { }
    },
    {
      id: "hp-pump",
      className: "absolute left-[87px] top-[534px] w-[453px] h-[326px] cursor-pointer",
      onClick: () => onClick("hppump", "HP PUMP")
    },
    {
      id: "lp-pump",
      className: "absolute left-[3234px] top-[536px] w-[444px] h-[316px] cursor-pointer",
      onClick: () => onClick("lppump", "LP PUMP")
    }
  ];

  useEffect(() => {
    const eventSource = new EventSource(`https://dashboard.thermosoft.com.tr/api/calculatedorc/evhp`);

    eventSource.addEventListener("periodic-event-orc-calculated", (e) => {

      try {
        const jsonData = JSON.parse(e.data);
        setBoxData(jsonData);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    });

    eventSource.onerror = (err) => {
      console.error("EventSource hatası:", err);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, []);


  // Anlık ORC verisi için EventSource
  useEffect(() => {
    if (selectedDate) return; // Eğer bir tarih seçiliyse, canlı veri güncellemelerini durdur

    const eventSource = new EventSource(`https://dashboard.thermosoft.com.tr/api/santral/scada-values`);

    eventSource.addEventListener("periodic-event-santral-values", (e) => {
      try {
        const jsonData = JSON.parse(e.data);
        setDatas(jsonData);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    });

    eventSource.onerror = (err) => {
      console.error("EventSource hatası:", err);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [selectedDate]);

  // Anlık Calculated ORC verisi için EventSource
  useEffect(() => {
    if (selectedDate) return; // Eğer bir tarih seçiliyse, canlı veri güncellemelerini durdur

    const eventSource = new EventSource(`https://dashboard.thermosoft.com.tr/api/calculatedorc/calculations`);

    eventSource.addEventListener("periodic-event-calculations", (e) => {
      try {
        const jsonData = JSON.parse(e.data);
        setCalculatedValues(jsonData);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    });

    eventSource.onerror = (err) => {
      console.error("EventSource hatası:", err);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [selectedDate]);



  const modalRef = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className="w-full h-full flex flex-1 overflow-hidden relative"
      id="wrapper"
    >
      <div className="absolute w-[350px] left-0 sm:top-0 md:bottom-0 md:top-auto flex  flex-row z-30">
        <DateTimePicker startDate={startDate} setStartDate={setStartDate} onChange={handleDateChange} />
        <button
          onClick={handleTodayClick}
          disabled={!selectedDate}
          className={`${!selectedDate ? "rotating-border" : ""} bg-sideheadbar-color relative w-[40px] h-[40px] m-auto border-none color-[#333] cursor-pointer overflow-hidden flex items-center justify-center rounded-full`}
        >
          <IoIosPlay size={22} className="fill-text-color" />
        </button>
      </div>




      <QuickPinchZoom
        tapZoomFactor={0}
        centerContained
        minZoom={-4}
        maxZoom={4}
        onUpdate={onUpdate}
      >
        <div
          id="wrapper"
          ref={divRef}
          className="w-[3780px] h-[1960px] relative "
        >

          {positions[company].initialPositions.map((item, index) => {
            const dataValue = (datas.orc && datas.orc[item.value] !== undefined
              ? datas.orc[item.value]
              : datas[item.value]) || "00.00";
            const t_evap_lp_in = calculatedValues[item.value];

            return (
              <div
                key={index}
                className={`absolute left-[${item.left}px] top-[${item.top}px] min-w-[200px] h-10 bg-table-head text-text-color flex items-center justify-center rounded-sm border border-text-color p-1`}
                style={{
                  transform: `translate(${item.left}px, ${item.top}px)`,
                }}
              >
                <p className="text-3xl font-semibold">
                  {item.symbol === "T amb" ? (<span>T<sub>amb</sub>: </span>) : item.symbol}
                  {t_evap_lp_in
                    ? `${item.brim === "t/s" ? (parseFloat(t_evap_lp_in) * 3.6).toFixed(2) : parseFloat(t_evap_lp_in).toFixed(2)} ${item.brim}`
                    : `${item.brim === "t/s" ? (parseFloat(dataValue) * 3.6).toFixed(2) : parseFloat(dataValue).toFixed(2)} ${item.brim}`
                  }
                </p>
              </div>
            );
          })}

          {/* input   250 t/h */}
          <div
            className={`absolute left-[1686px] top-[1227px] min-w-[200px] h-10 bg-table-head text-text-color flex items-center justify-center rounded-sm border border-text-color p-4`}
          >
            <p className="text-3xl font-semibold">
              0.0 t/h
            </p>
          </div>

          {positions[company].calculationPositions.map((item, index) => {

            const dataValue = calculatedValues[item.value];

            const eta = item.eta ? item.eta : '';

            return (
              <div
                key={index}
                className={`absolute left-[${item.left}px] text-lg top-[${item.top}px] min-w-[200px] h-10 bg-calculated-label border-2 border-calculated-label-border text-white flex items-center justify-center rounded-sm  p-2 `}
                style={{
                  transform: `translate(${item.left}px, ${item.top}px)`,
                }}
              >
                <p className="text-3xl font-semibold">
                  {item.symbol && item.symbol}
                  {item.eta && item.eta}
                  {dataValue !== undefined
                    ? ` ${eta !== "" ? (parseFloat(dataValue) * 100).toFixed(2) : item.brim === "kW" ? parseFloat(dataValue).toFixed(0) : item.brim === "t/s" ? (parseFloat(dataValue) * 3.6).toFixed(2) : parseFloat(dataValue).toFixed(2)} `
                    : ` 00.00 `
                  }
                  {item.brim}
                </p>
              </div>
            );
          })}



          {theme && (
            <img
              className="w-[3780px] h-[1960px]"
              src={
                theme === "dark"
                  ? photoMap.orc[company].dark
                  : photoMap.orc[company].light
              }
              alt="orc-page.png"
            />
          )}
          <div>

            {elements.map((el) => (
              <div
                key={el.id}
                id={el.id}
                className={el.className}
                onClick={el.onClick}
              />
            ))}


          </div>
        </div>
      </QuickPinchZoom>

      {isOpen && (
        <Draggable cancel=".cancel-drag">
          <div
            ref={modalRef}
            className="bg-sideheadbar-color rounded-md text-text-color 
       flex flex-col absolute w-full xl:w-[40%] z-10 self-center p-6 xl:right-[29%] shadow-xl shadow-background-color"
          >
            <div className="bg-border-color rounded-md flex flex-row items-center justify-between p-2 px-4 mb-3">
              <h4 className="3xl:text-4xl font-semibold">{modalTitle}</h4>
              <button
                className="cancel-drag absolute right-8 text-gray-400 bg-border-color  hover:text-gray-900 rounded-lg text-sm w-7 h-7 xl:w-10 xl:h-10 ml-auto inline-flex justify-center items-center dark:hover:text-white"
                onClick={(e) => {
                  e.stopPropagation(); // Olayın yukarıya iletilmesini engelle
                  setIsOpen(false);
                }}
              >
                <IoIosClose className="hover:scale-125" size={25} />
              </button>
            </div>
            <div className="flex flex-row justify-between p-3 border-2 border-border-color text-lg 3xl:text-2xl 4xl:text-5xl font-bold rounded-t-md ">
              <p className="w-1/3 text-center">Property</p>
              <p className="w-1/3 text-center">Value</p>
              <p className="w-1/3 text-center">Unit</p>
            </div>
            <div className="flex flex-1 flex-col text-sm 3xl:text-lg 4xl:text-4xl justify-evenly  border-2 border-border-color rounded-b-md">
              {renderDataModal()} {/* Render the data dynamically */}
            </div>
          </div>
        </Draggable>
      )}


      {/* Open button -> Notification modal  */}
      <div
        onClick={() => setNotificationModal(true)}
        className="absolute animate-bounce bottom-5 right-5 bg-table-head hover:scale-125 cursor-pointer w-10 h-10 3xl:w-20 3xl:h-20 rounded-lg flex items-center justify-center"
      >
        <BsExclamationCircle className="text-logo-color text-xl 3xl:text-4xl" />
      </div>

      {/* Notification table  */}
      <div
        className={`bg-sideheadbar-color border border-border-color absolute bottom-0 right-0 w-full z-30 flex flex-col h-2/3 animate-slideUp ${notificationModal ? "flex" : "hidden"
          }`}
      >
        <div className="flex w-full items-center h-12 3xl:h-22 justify-end p-3">
          <button
            onClick={() => setNotificationModal(false)}
            type="button"
            class="absolute top-3 right-3 text-text-color hover:bg-table-head  rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
          >
            <IoClose size={28} />
          </button>
        </div>

        <div className="overflow-hidden flex flex-1 my-3 w-[98%] h-[98%] self-center">
          <NotificationTable />
        </div>
      </div>
    </div>
  );
};

export default Orc;
