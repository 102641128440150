import React, { useEffect } from "react";
import PieChart from "../components/charts/PieChart";
import LineChart from "../components/charts/LineChart";
import BarChart from "../components/charts/BarChart";
import { colors } from "../consts/colors";
import { useState } from "react";
import AreaChart from "../components/charts/AreaChart";
import ElectricityConsumptionsBarChart from "../components/charts/ElectricityConsumptionsBarChart";
import { useAuth } from "../providers/AuthProvider";
import { useTranslation } from 'react-i18next';


const xlabelsBarConsumptions = ["A1", "A2", "A3", "A4", "A5", "A6", "A7"];

const Dashboard = () => {
  const { t } = useTranslation();
  const xlabelsBar1 = [
   t('months.January'),
   t('months.February'),
   t('months.March'),
   t('months.April'),
   t('months.May'),
   t('months.June'),
   t('months.July'),
   t('months.August'),
   t('months.September'),
   t('months.October'),
   t('months.November'),
   t('months.December'),
  ];

 
  const xlabelsBar2 = [
    t('date.date-sunday'),
    t('date.date-monday'),
    t('date.date-tuesday'),
    t('date.date-wednesday'),
    t('date.date-thursday'),
    t('date.date-friday'),
    t('date.date-saturday'),
  ];
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [netWellProduction, setNetWellProduction] = useState([]);
  const [grossWellProduction, setGrossWellProduction] = useState([]);
  const [predictedMontlySales, setPredictedMontlySales] = useState([]);
  const [happenedMontlySales, setHappenedMontlySales] = useState([]);
  const [electricityConsumptions, setElectricityConsumptions] = useState([]);
  const [labelsElectricityConsumptions, setLabelsElectricityConsumptions] =
    useState([]);
  const [predictedWeeklySales, setPredictedWeeklySales] = useState([]);
  const [happenedWeeklySales, setHappenedWeeklySales] = useState([]);
  const [grossPowerProduction, setGrossPowerProduction] = useState([]);
  const [netPowerProduction, setNetPowerProduction] = useState([]);
  const auth = useAuth();

 

  {
    /* get montly sales */
  }
  useEffect(() => {
    const eventSource = new EventSource(
      "http://192.168.250.235:8080/api/v1/santral/monthly-sales"
    );

    eventSource.addEventListener("periodic-event-monthly-sales", (e) => {
      try {
        const data = JSON.parse(e.data);
        if (data && Array.isArray(data)) {
          const happened = data.map((item) => item.happenedSale);
          const predicted = data.map((item) => item.predictedSale);
          setPredictedMontlySales(predicted);
          setHappenedMontlySales(happened);
        } else {
          console.error(
            "Received data is not in the expected format or is null:",
            data
          );
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    });

    return () => {
      eventSource.close();
    };
  }, []);

  
  {
    /* Power production */
  }
  useEffect(() => {
    const eventSource = new EventSource(
      "http://192.168.250.235:8080/api/v1/santral/power-production"
    );

    eventSource.addEventListener("periodic-event-power-production", (e) => {
      try {
        const data = JSON.parse(e.data);
        if (data && Array.isArray(data)) {
          const net = data.map((item) => item.netPowerProduction);
          const gross = data.map((item) => item.grossPowerProduction);
          setNetPowerProduction(net);
          setGrossPowerProduction(gross);
        } else {
          console.error(
            "Received data is not in the expected format or is null:",
            data
          );
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    });

    return () => {
      eventSource.close();
    };
  }, []);

  useEffect(() => {
    const eventSource = new EventSource(
      "http://172.20.10.2:8080/api/v1/santral/weekly-sales"
    );

    eventSource.addEventListener("periodic-event-weekly-sales", (e) => {
      try {
        const data = JSON.parse(e.data);
        if (data && Array.isArray(data)) {
          const happened = data.map((item) => item.happenedSale);
          const predicted = data.map((item) => item.predictedSale);
          setPredictedWeeklySales(predicted);
          setHappenedWeeklySales(happened);

        } else {
          console.error(
            "Received data is not in the expected format or is null:",
            data
          );
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    });

    return () => {
      eventSource.close();
    };
  }, []);


  {
    /* get well net production data */
  }
  useEffect(() => {
    const eventSource = new EventSource(
      `http://172.20.10.2:8080/api/v1/santral/net-well-production`
    );

    eventSource.addEventListener("periodic-event-well-production", (e) => {
      try {
        const jsonData = JSON.parse(e.data);

        if (jsonData && Array.isArray(jsonData)) {
          const roundedData = jsonData.map((num) => {
            if (num == null) {
              return "00.00";
            }
            return typeof num === "number" ? parseFloat(num.toFixed(2)) : num;
          });

          setNetWellProduction(roundedData);
        } else {
          console.error("Received data is not an array:", jsonData);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    });

    return () => {
      eventSource.close();
    };
  }, []);

  {
    /* get well gross production data */
  }
  useEffect(() => {
    const eventSource = new EventSource(
      `http://172.20.10.2:8080/api/v1/santral/gross-well-production`
    );

    eventSource.addEventListener(
      "periodic-event-well-gross-production",
      (e) => {
        try {
          const jsonData = JSON.parse(e.data);

          if (Array.isArray(jsonData)) {
            const roundedData = jsonData.map((num) => {
              if (num == null) {
                return "00.00";
              }
              return typeof num === "number" ? parseFloat(num.toFixed(2)) : num;
            });

            setGrossWellProduction(roundedData);
          } else {
            console.error("Received data is not an array:", jsonData);
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      }
    );

    return () => {
      eventSource.close();
    };
  }, []);

  {
    /* get electricity consumptions data */
  }
  useEffect(() => {
    const eventSource = new EventSource(
      `http://172.20.10.2:8080/api/v1/santral/electricity-consumptions`
    );

    eventSource.addEventListener(
      "periodic-event-electricity-consumptions",
      (e) => {
        try {
          const jsonData = JSON.parse(e.data);

          if (typeof jsonData === "object" && !Array.isArray(jsonData)) {
            // Extract and set labels
            const newLabels = Object.keys(jsonData);
            setLabelsElectricityConsumptions(newLabels);

            // Process and round the data, then update the data state
            const roundedData = newLabels.reduce((acc, key) => {
              const value = jsonData[key];
              acc[key] = value == null ? "00.00" : parseFloat(value.toFixed(2));
              return acc;
            }, {});

            setElectricityConsumptions(roundedData);
          } else {
            console.error(
              "Received data is not in the expected format:",
              jsonData
            );
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      }
    );

    return () => {
      eventSource.close();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("theme", () => {
      setTheme(localStorage.getItem("theme"));
    });
  }, []);

  return (
    <div className="text-text-color w-full h-full ">
      <div className="w-full h-full grid lg:grid-rows-2 grid-cols-1 md:grid-cols-2  lg:grid-cols-5 gap-3">
        <div className="h-80 lg:h-full bg-sideheadbar-color border border-border-color rounded-md">
          {netWellProduction && (
            <PieChart
              data={netWellProduction}
              labels={[
                "Well 1",
                "Well 2",
                "Well 3",
                "Well 1",
                "Well 2",
                "Well 3",
                "Well 1",
                "Well 2",
                "Well 3",
                "Well 1",
                "Well 2",
                "Well 3",
                "Well 1",
                "Well 2",
                "Well 3",
              ]}
              title={t('well.well-net-production')}
              backgroundColors={colors.chartBackgroundColors}
              theme={theme}
            />
          )}
        </div>
        <div className="h-80 lg:h-full bg-sideheadbar-color lg:col-span-2 border border-border-color rounded-md">
          <BarChart
            data1={happenedMontlySales && happenedMontlySales}
            data2={predictedMontlySales && predictedMontlySales}
            titleValue1={t('sales.real')}
            titleValue2={t('sales.predicted')}
            xAxisLabel={xlabelsBar1}
            title={t('sales.montly-sales')}
            theme={theme}
          />
        </div>
        <div className="h-80 lg:h-full bg-sideheadbar-color lg:col-span-2 border border-border-color rounded-md">
          <AreaChart
            data1={happenedWeeklySales && happenedWeeklySales}
            data2={predictedWeeklySales && predictedWeeklySales}
            title={t('sales.sales')}
            theme={theme}
          />
        </div>
        <div className="h-80 lg:h-full bg-sideheadbar-color border border-border-color rounded-md">
          <PieChart
            data={grossWellProduction && grossWellProduction}
            labels={[
              "Well 1",
              "Well 2",
              "Well 3",
              "Well 1",
              "Well 2",
              "Well 3",
              "Well 1",
              "Well 2",
              "Well 3",
              "Well 1",
              "Well 2",
              "Well 3",
              "Well 1",
              "Well 2",
              "Well 3",
            ]}
            title={t('well.well-gross-production')}
            backgroundColors={colors.chartBackgroundColors2}
            theme={theme}
          />
        </div>
        <div className="h-80 lg:h-full bg-sideheadbar-color md:col-span-2 border border-border-color rounded-md">
          <ElectricityConsumptionsBarChart
            titleValue1={"Electricity consumption"}
            titleValue2={"calculated"}
            xAxisLabel={
              labelsElectricityConsumptions && labelsElectricityConsumptions
            }
            title={t('sales.electricity-consumptions')}
            theme={theme}
            datas={electricityConsumptions && electricityConsumptions}
          />
        </div>
        <div className="h-80 lg:h-full bg-sideheadbar-color md:col-span-2 border border-border-color rounded-md">
          <LineChart
            data1={grossPowerProduction}
            data2={netPowerProduction}
            title={t('sales.power-production')}
            theme={theme}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
