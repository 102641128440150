import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import Layout from "./Layout";

const PrivateRoute = () => {
  const { token ,logOut} = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Başlangıç durumu null olarak ayarlandı

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch("https://dashboard.thermosoft.com.tr/api/check-auth", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include'
        });

        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false); 
          logOut();
        }
      } catch (error) {
        setIsAuthenticated(false);
        logOut();
      }
    };

    if (token) {
      checkAuth();
    } else {
      setIsAuthenticated(false);
      logOut();
    }
  }, [token]);

  if (isAuthenticated === null) {
    return <div className="w-screen h-screen flex flex-1 items-center justify-center text-text-color bg-background-color">
      <img alt="logo" src={require("../assets/logo.png")}  className="w-24 h-16 xs:w-20 xs:h-12 3xl:w-52 3xl:h-36 self-center"/>
    </div>; // İlk kontrol sırasında bir yükleme durumu göstermek
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <Layout />;
};

export default PrivateRoute;
