import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { setDeleteModalOpen, setUpdateModalOpen } from "../../redux/modalSlice";
import { useDispatch } from "react-redux";
import { RiDeleteBin7Line } from "react-icons/ri";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useAuth } from "../../providers/AuthProvider";
import { useTranslation } from 'react-i18next';


export default function AdministratorTable({ rows }) {
  const { t } = useTranslation();

  const columns = [
    { id: "id", label: "ID", minWidth: 50 },
    { id: "power_plant_name", label: t("administrator.power-plant-name"), minWidth: 150 },
    {
      id: "name",
      label: t("administrator.name"),
      minWidth: 100,
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "surname",
      label: t("administrator.surname"),
      minWidth: 100,
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "username",
      label: t("administrator.username"),
      minWidth: 100,
      format: (value) => value.toFixed(2),
    },
    {
      id: "phone_number",
      label: t("administrator.phone"),
      minWidth: 100,
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "email",
      label: "Email",
      minWidth: 170,
      format: (value) => value.toLocaleString("en-US"),
    },
  
    {
      id: "action",
      label: t("administrator.action"),
      minWidth: 100,
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
  
  const dispatch = useDispatch();



  return (
    <Paper
      className="!bg-background-color"
      sx={{ width: "100%", overflow: "hidden", fontPalette: "dark" }}
    >
      <TableContainer sx={{ maxHeight: "100%" }}>
        <Table
          className="!text-text-color min-w-full !bg-table-head"
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead className="!text-text-color min-w-full !bg-table-head">
            <TableRow >
              {columns.map((column) => {
                return (
                  <TableCell 
                  sx={{
                    lineHeight:"1rem"
                  }}
                    key={column.id}
                    align={"center"}
                    style={{ minWidth: column.minWidth }}
                    className="!bg-table-head !text-text-color !p-2 !text-[9px] md:!text-xs lg:!text-sm"
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow
                  className="!bg-sideheadbar-color hover:!bg-border-color"
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                >
                  {columns.map((column, index) => {
                    const value = row[column.id];

                    if (columns.length === index + 1) {
                      return (
                        <TableCell
                          className="!text-text-color !border-background-color !border-b-8 !text-[9px] md:!text-[10px] lg:!text-sm !py-1"
                          key={"action"}
                          align={"center"}
                        >
                          <div className="flex h-full w-full items-center justify-center  flex-row gap-4">
                            <div
                              onClick={() => {
                                dispatch(setUpdateModalOpen({value:true,id:row.id}));
                              }}
                              className="w-8 h-8 cursor-pointer bg-[#40C956] bg-opacity-10  rounded-full flex items-center justify-center hover:scale-125"
                            >
                              <MdOutlineModeEditOutline
                                size={18}
                                color="#40C956"
                              />
                            </div>
                            <div
                              onClick={() => {
                                dispatch(setDeleteModalOpen({value:true,id:row.id}));
                              }}
                              className="w-8 h-8 cursor-pointer bg-[#E51F21] bg-opacity-10  rounded-full flex items-center justify-center hover:scale-125"
                            >
                              <RiDeleteBin7Line color="#E51F21" size={20} />
                            </div>
                          </div>
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        className="!text-text-color !border-background-color !border-b-8 !text-[9px] md:!text-[10px] lg:!text-sm !py-1"
                        key={column.id}
                        align={"center"}
                      >
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
