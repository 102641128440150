const positions = {
  1: {
    initialPositions: [
      {
        left: 1048,
        top: 135,
        value: "tt1262_tt1262_pv_out",
        brim: "°C"
      },
      {
        left: 1048,
        top: 185,
        value: "pt1282_pt1282_pv_out",
        brim: "bara"
      },
      {
        left: 394,
        top: 397,
        value: "tt1060_tt1060_pv_out",
        brim: "°C"
      },
      {
        left: 394,
        top: 447,
        value: "pt1180_pt1180_pv_out",
        brim: "bara"
      },
      {
        left: 361,
        top: 866,
        value: "fy1050_fy1050_pv_out",
        brim: "m³/h"
      },
      {
        left: 361,
        top: 1187,
        value: "tt1053_tt1053_pv_out",
        brim: "°C"
      },
      {
        left: 361,
        top: 1237,
        value: "pt1078_pt1078_pv_out",
        brim: "bara"
      },
      {
        left: 675,
        top: 568,
        value: "_0brs_tt306_0brs_tt306_pv_out",
        brim: "°C"
      },
      {
        left: 675,
        top: 621,
        value: "_0brs_pt308_0brs_pt308_pv_out",
        brim: "bara"
      },
      {
        left: 675,
        top: 872,
        value: "tt0352_tt0352_pv_out",
        brim: "°C"
      },
      {
        left: 675,
        top: 922,
        value: "pt0351_pt0351_pv_out",
        brim: "bara"
      },
      {
        left: 675,
        top: 1329,
        value: "tt1088_tt1088_pv_out",
        brim: "°C"
      },
      {
        left: 1189,
        top: 483,
        value: "tt1075_tt1075_pv_out",
        brim: "°C"
      },
      {
        left: 1189,
        top: 533,
        value: "pt1074_pt1074_pv_out",
        brim: "bara"
      },
      {
        left: 1278,
        top: 872,
        value: "tt1071_tt1071_pv_out",
        brim: "°C"
      },
      {
        left: 1278,
        top: 922,
        value: "pt1070_pt1070_pv_out",
        brim: "bara"
      },
      {
        left: 1272,
        top: 1177,
        value: "tt0354_tt0354_pv_out",
        brim: "°C"
      },
      {
        left: 1272,
        top: 1229,
        value: "pt0353_pt0353_pv_out",
        brim: "bara"
      },
      {
        left: 1983,
        top: 1229,
        value: "tt0361_tt0361_pv_out",
        brim: "°C"
      },
      {
        left: 1288,
        top: 1762,
        value: "_0brs_tt308a_0brs_tt308a_pv_out",
        brim: "°C"
      },
      {
        left: 1288,
        top: 1812,
        value: "_0brs_pt310a_0brs_pt310a_pv_out",
        brim: "bara"
      },
      {
        left: 2585,
        top: 135,
        value: "tt2262_tt2262_pv_out",
        brim: "°C"
      },
      {
        left: 2585,
        top: 185,
        value: "pt2282_pt2282_pv_out",
        brim: "bara"
      },
      {
        left: 2453,
        top: 483,
        value: "tt2075_tt2075_pv_out",
        brim: "°C"
      },
      {
        left: 2453,
        top: 533,
        value: "pt2074_pt2074_pv_out",
        brim: "bara"
      },
      {
        left: 2595,
        top: 1089,
        value: "tt0356_tt0356_pv_out",
        brim: "°C"
      },
      {
        left: 2595,
        top: 1141,
        value: "pt0355_pt0355_pv_out",
        brim: "bara"
      },
      {
        left: 2595,
        top: 1746,
        value: "_0brs_tt308b_0brs_tt308b_pv_out",
        brim: "°C"
      },
      {
        left: 2595,
        top: 1796,
        value: "_0brs_pt310b_0brs_pt310b_pv_out",
        brim: "bara"
      },
      {
        left: 2957,
        top: 1089,
        value: "tt2071_tt2071_pv_out",
        brim: "°C"
      },
      {
        left: 2957,
        top: 1141,
        value: "pt2070_pt2070_pv_out",
        brim: "bara"
      },
      {
        left: 3187,
        top: 397,
        value: "tt2060_tt2060_pv_out",
        brim: "°C"
      },
      {
        left: 3187,
        top: 447,
        value: "pt2180_pt2180_pv_out",
        brim: "bara"
      },
      {
        left: 3220,
        top: 863,
        value: "fy2050_fy2050_pv_out",
        brim: "m³/h"
      },
      {
        left: 3220,
        top: 1262,
        value: "tt2053_tt2053_pv_out",
        brim: "°C"
      },
      {
        left: 3220,
        top: 1312,
        value: "pt2077_pt2077_pv_out",
        brim: "bara"
      },
      {
        left: 2595,
        top: 1903,
        value: "ft0499_ft0499_pv_out",
        brim: "m³/h"
      },
      {
        left: 1574,
        top: 798,
        value: "_0hps_tit_305_ma_pv_out",
        brim: "°C"
      },
      {
        left: 1819,
        top: 99,
        value: "tt1150_tt1150_pv_out",
        brim: "°C",
        symbol:"T amb"
      },
      {
        left: 2234,
        top: 1229,
        value: "t_evap_lp_in",
        brim: "°C"
      },
      {
        left: 1574,
        top: 748,
        value: "_0hps_pit_311_ma_pv_out",
        brim: "bara"
      },
     
    ],
    calculationPositions: [
      {
        left: 164,
        top: 5,
        value: "acc_w_hp",
        brim: "kW",
        symbol:"Q:"
      },
      {
        left: 374,
        top: 5,
        value: "acc_eta_hp",
        brim: "%",
        eta: "Ɛ:"
      },
      {
        left: 1253,
        top: 280,
        value: "exturbine_w_hp",
        brim: "kW",
        symbol:"P:"
      },
      {
        left: 1253,
        top: 332,
        value: "exturbine_eta_isent_hp",
        brim: "%",
        eta: "η:"
      },
      {
        left: 1819,
        top: 305,
        value: "exturbine_w_total",
        brim: "kW"
      },
      {
        left: 2390,
        top: 280,
        value: "exturbine_w_lp",
        brim: "kW",
        symbol:"P:"
      },
      {
        left: 2390,
        top: 332,
        value: "exturbine_eta_isent_lp",
        brim: "%",
        eta: "η:"
      },
      {
        left: 864,
        top: 776,
        value: "q_ev1001",
        brim: "kW",
        symbol:"Q:"
      },
      {
        left: 1082,
        top: 776,
        value: "eta_ev1001",
        brim: "%",
        eta: "Ɛ:"
      },
      {
        left: 864,
        top: 1074,
        value: "q_ph1002",
        brim: "kW",
        symbol:"Q:"
      },
      {
        left: 1082,
        top: 1074,
        value: "eta_ph1002",
        brim: "%",
        eta: "Ɛ:"
      },
      {
        left: 869,
        top: 1643,
        value: "q_ph1001a_b",
        brim: "kW",
        symbol:"Q:"
      },
      {
        left: 1088,
        top: 1643,
        value: "eta_ph1001a_b",
        brim: "%",
        eta: "Ɛ:"
      },
      {
        left: 2541,
        top: 786,
        value: "q_ev2001",
        brim: "kW",
        symbol:"Q:"
      },
      {
        left: 2761,
        value: "eta_ev2001",
        top: 786,
        brim: "%",
        eta: "Ɛ:"
      },
      {
        left: 2541,
        top: 1643,
        value: "q_ph2001a_b",
        brim: "kW",
        symbol:"Q:"
      },
      {
        left: 2761,
        top: 1643,
        value: "eta_ph2001a_b",
        brim: "%",
        eta: "Ɛ:"
      },
      {
        left: 3206,
        top: 5,
        value: "acc_w_lp",
        brim: "kW",
        symbol:"Q:"
      },
      {
        left: 3416,
        top: 5,
        value: "acc_eta_lp",
        brim: "%",
        eta: "Ɛ:"
      },
      {
        left: 1574,
        top: 698,
        value: "m_steam_in",
        brim: "t/h",
      },
      {
        left: 361,
        top: 916,
        value: "exturbine_m_hp_kgs",
        brim: "t/s"
      },
      {
        left: 3220,
        top: 913,
        value: "exturbine_m_lp_kgs",
        brim: "t/s"
      },
      {
        left: 1715,
        top: 240,
        value: "hp_termal_verim",
        brim: "%",
        eta: "HP Ɛ:"
      },
       {
        left: 1925,
        top: 240,
        value: "lp_termal_verim",
        brim: "%",
        eta: "LP Ɛ:"
      },
      {
        left: 2234,
        top: 1101,
        value: "w_electrical",
        brim: "kW",
        symbol:"P:"
      },
      {
        left: 1826,
        top: 916,
        value: "q_reboiler",
        brim: "kW",
        symbol:"Q:"
      },
      {
        left: 1826,
        top: 966,
        value: "w_lp_reboiler",
        brim: "kW",
        symbol:"W:"
      },
      {
        left: 2805,
        top: 1903,
        value: "m_brine_b1",
        brim: "t/s"
      },
      {
        left: 1819,
        top: 20,
        value: "predictionAcc",
        symbol:"ACC:",
        brim: "kW"
      }
    ],
  },
  2: {
    initialPositions: [],
    calculationPositions: [],
  },
};


export default positions;