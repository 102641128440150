import React, { useEffect, useLayoutEffect, useState } from "react";
import { BiSolidDashboard } from "react-icons/bi";
import { CgLayoutPin } from "react-icons/cg";
import { TbDropletBolt } from "react-icons/tb";
import { PiSealQuestionBold } from "react-icons/pi";
import { AiOutlineLineChart } from "react-icons/ai";
import {
  BsTable,
  BsFillSunFill,
  BsFillMoonFill,
  BsExclamationCircle,
} from "react-icons/bs";
import { RiUserSettingsFill } from "react-icons/ri";
import { NavLink, useLocation } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";
import { MdOutlineLanguage } from "react-icons/md";
import { LuSettings } from "react-icons/lu";
import "./styles.css";
import Switch from "react-switch";
import { useAuth } from "../providers/AuthProvider";
import { toggleLang } from "../redux/langSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import WorkplaceSelector from "../components/WorkplaceSelector";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { checkLanguage } = useSelector((store) => store.checkLang);
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState("dashboard");
  const [checked, setChecked] = useState(
    localStorage.getItem("theme") === "dark" ? true : false
  );
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const auth = useAuth();
  const location = useLocation();


  useEffect(() => {
    const currentPath = location.pathname;
    const path = currentPath.substring(currentPath.lastIndexOf("/") + 1);
    setSelected(path === "/" ? "dashboard" : path);
  }, [location.pathname]);

  const changeTheme = (checked) => {
    setTheme(checked ? "dark" : "light");

    if (checked === true) {
      document.querySelector("html").classList.remove("light");
      document.querySelector("html").classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.querySelector("html").classList.remove("dark");
      document.querySelector("html").classList.add("light");
      localStorage.setItem("theme", "light");
    }
  };

  const items = [
    {
      label: t("dash.dash-dashboard"),
      icon: <BiSolidDashboard size={22} />,
      to: "",
    },
    {
      label: "ORC",
      icon: <CgLayoutPin size={22} />,
      to: "orc",
    },
    {
      label: "BOP",
      icon: <TbDropletBolt size={22} />,
      to: "bop",
    },
    {
      label: t("dash.dash-prediction"),
      icon: <PiSealQuestionBold size={22} />,
      to: "prediction",
    },
    {
      label: t("dash.dash-chart"),
      icon: <AiOutlineLineChart size={22} />,
      to: "chart",
    },
    {
      label: t("dash.dash-table"),
      icon: <BsTable size={20} />,
      to: "table",
    },
    {
      label: "Administrator",
      icon: <RiUserSettingsFill size={22} />,
      to: "administrator",
    },
  ];

  const bottomItems = [
    {
      label: t("dash.dash-help"),
      icon: <BsExclamationCircle size={20} />,
      to: "help",
    },
    {
      label: t("dash.dash-settings"),
      icon: <LuSettings size={22} />,
      to: "settings",
    },
  ];

  const handleChange = (checked) => {
    setChecked(checked);
    changeTheme(checked);
    window.dispatchEvent(new Event("theme"));
  };

  useEffect(() => {
    changeTheme(checked);
  }, []);

  const handleLogout = () => {
    auth.logOut();
  };

  const handleChangeLang = () => {
    const newLang = checkLanguage ? 'en' : 'tr';
    i18n.changeLanguage(newLang);
    dispatch(toggleLang());
  };


  return (
    <div className="hidden overflow-auto overflow-x-hidden md:flex md:flex-col text-text-color justify-between items-center h-full w-16 xl:w-44 3xl:w-60 5xl:w-80 bg-sideheadbar-color border-r border-border-color ">
      {/* sayfalar arası geçiş */}
      <div className="flex flex-col gap-1 w-14 xl:w-44 3xl:w-60 5xl:w-80 items-center justify-center py-1 xl:py-4">
        {items.map((item, index) =>
          (item.label !== "Administrator" || auth.userRole === "SUPERADMIN" || auth.userRole === "ADMIN") && (
            <NavLink
              key={index}
              onClick={() => setSelected(item.to)}
              to={item.to}
              className={`flex flex-row w-5/6 lg:w-3/4 h-10 justify-center xl:justify-normal items-center md:gap-2 md:px-2 rounded-sm cursor-pointer
       ${item.to === selected
                  ? "bg-logo-color text-black"
                  : "text-color hover:dark:bg-[#2C2C2C] hover:bg-component-color"
                }`}
            >
              <div>{item.icon}</div>
              <p className="hidden xl:block sm:text-xs lg:text-sm 4xl:text-2xl font-semibold">
                {item.label}
              </p>
            </NavLink>
          )
        )}
        {auth.userRole === "SUPERADMIN" && <WorkplaceSelector />}
      </div>


      <div className="flex flex-col bg-sideheadbar-color gap-1 w-14 xl:w-44 3xl:w-60 5xl:w-80 items-center justify-center py-1  border-t border-r border-border-color">
        {bottomItems.map((item, index) => (
          <NavLink
            key={index}
            onClick={() => setSelected(item.to)}
            to={item.to}
            className={`flex flex-row w-5/6 lg:w-3/4 h-10 justify-center xl:justify-normal items-center md:gap-2 md:px-2 rounded-sm cursor-pointer
       ${item.to === selected
                ? "bg-logo-color text-black"
                : "text-color hover:dark:bg-[#2C2C2C] hover:bg-component-color"
              }`}
          >
            <div>{item.icon}</div>
            <p className="hidden xl:block text-xs lg:text-sm 4xl:text-2xl font-semibold ">
              {item.label}
            </p>
          </NavLink>
        ))}


        <NavLink
          onClick={handleLogout}
          className={`flex flex-row w-5/6 lg:w-3/4 h-10 justify-center xl:justify-normal items-center md:gap-2 md:px-2 rounded-sm cursor-pointer
        "text-color hover:dark:bg-[#2C2C2C] hover:bg-component-color"}`}
        >
          <div>
            <IoLogOutOutline size={24} />
          </div>
          <p className="hidden xl:block text-xs lg:text-sm 4xl:text-2xl font-semibold ">
            {t('dash.dash-logout')}
          </p>
        </NavLink>
        <div className='switch mt-5 border-[3px] rounded-full border-border-color relative inline-block top-0 right-0'>
          <input type="checkbox" id="language-toggle" className='check-toggle  -ml-[9999px] invisible absolute check-toggle-round-flat' onChange={handleChangeLang} checked={checkLanguage} />
          <label htmlFor="language-toggle"></label>

          <span className='on'>
            ENG
          </span>
          <span className='off'>
            TR
          </span>
        </div>

        <div className="flex flex-row items-center space-x-1 text-xs">
          <text className="hidden lg:block">{t('dash.dash-light')}</text>
          <div className="border-[3px] rounded-full border-border-color">
            <Switch
              draggable={true}
              handleDiameter={20}
              height={26}
              width={50}
              offColor="#ffffff"
              onColor="#161616"
              onHandleColor="#FFC700"
              offHandleColor="#FFC700"
              checked={checked}
              onChange={handleChange}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                  className="text-text-color"
                >
                  <BsFillMoonFill size={14} />
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                  className="text-text-color"
                >
                  <BsFillSunFill size={18} />
                </div>
              }
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              className="react-switch"
              id="icon-switch"

            />
          </div>
          <text className="hidden lg:block">{t('dash.dash-dark')}</text>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
