import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import DatePicker from "../components/selectBoxes/DatePicker";
import MultiSelectBox from "../components/selectBoxes/MultiSelectBox";
import { BsDownload } from "react-icons/bs";
import { MultiLineChart } from "../components/multiCharts/MultiLineChart";
import { useSelector } from "react-redux";
import { saveAsPdf, savePng } from "../utils/saveChartj";
import { MultiBarChart } from "../components/multiCharts/MultiBarChart";
import { MultiScatterChart } from "../components/multiCharts/MultiScatterChart";
import { MultiAreaChart } from "../components/multiCharts/MultiAreaChart";
import IntervalSelectBox from "../components/selectBoxes/IntervalSelectBox";
import ChartTypeSelectBox from "../components/selectBoxes/ChartTypeSelectBox";
import { useTranslation } from 'react-i18next';
const colors = [
  "rgba(255, 99, 71, 1)",
  "rgba(60, 179, 113, 1)",
  "rgba(71, 160, 255, 1)",
  "rgba(197, 71, 255, 1)",
  "rgba(255, 181, 0, 1)",
  "rgba(255, 102, 207, 1)",
];

const backgroundColors = [
  "rgba(255, 99, 71, 0.3)",
  "rgba(60, 179, 113, 0.3)",
  "rgba(71, 160, 255, 0.3)",
  "rgba(197, 71, 255, 0.3)",
  "rgba(255, 181, 0, 0.3)",
  "rgba(255, 102, 207, 0.3)",
];

const Chart = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [theme, setTheme] = useState(localStorage.getItem("theme"));

  const dateRange = useSelector((state) => state.selectedOptions.date);
  const values = useSelector((state) => state.selectedOptions.chartValues);
  const chartType = useSelector((state) => state.selectedOptions.chartType);
  const interval = useSelector((state) => state.selectedOptions.interval);
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();


  
  useEffect(() => {

    setIsLoading(true);
    const getDataByDateRange = async () => {
      if (!dateRange || !values || !interval) {
        return;
      }
      const startDate = dateRange[0];
      const endDate = dateRange[1];

      try {
        const baseUrl = "https://dashboard.thermosoft.com.tr/api/historyorc";

        const columnNames = values.map((item) => item.value).join(",");
      
        const queryParams = new URLSearchParams();
        queryParams.append("startDate", startDate);
        queryParams.append("endDate", endDate);
        queryParams.append("interval", interval.value);
        queryParams.append("columnName", columnNames);

        const url = `${baseUrl}/selectedvalues?${queryParams.toString()}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

      
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const fetchedData = await response.json();
      
        if (values.length > 0) {
          const datetime = fetchedData["zaman_etiketi"];
          setDate(datetime);

          delete fetchedData["zaman_etiketi"];

          const dataArrays = Object.values(fetchedData).map((value) => value);
          setData(dataArrays);
          setIsLoading(false);
       
        } else {
          console.error(
            "Received data is not in the expected format or is empty."
          );
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getDataByDateRange();
  }, [values, dateRange, interval]);

  useEffect(() => {
    window.addEventListener("theme", () => {
      setTheme(localStorage.getItem("theme"));
    });
  }, []);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  return (
    <div className="h-full bg-background-color w-full">
      <div className="flex flex-col overflow-hidden w-full h-full flex-1 gap-3">
        {/* Select boxes */}
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-around px-0.5  w-full gap-1 sm:gap-3 3xl:gap-6 ">
          <div className="w-full h-10 3xl:!h-16 4xl:!h-20 z-40">
            <MultiSelectBox selectedColors={colors} />
          </div>
          <div className="flex md:flex-row flex-col gap-1 sm:gap-3 3xl:gap-6  items-center">
            <div className="w-full h-10 3xl:!h-16 4xl:!h-20 z-30">
              <DatePicker />
            </div>
            <div className="w-full h-10 3xl:!h-16 4xl:!h-20 z-20">
              <IntervalSelectBox />
            </div>
            <div className="w-full h-10 3xl:!h-16 4xl:!h-20 z-10 flex flex-row gap-1 sm:gap-3">
              <div className="flex-1">
                <ChartTypeSelectBox />
              </div>

              <div
                className="relative flex h-10 3xl:!h-16 4xl:!h-20 z-1 text-text-color"
                ref={menuRef}
              >
                <div
                  className="bg-sideheadbar-color flex items-center justify-center w-10 h-10 3xl:h-16 3xl:w-16 4xl:!h-20 4xl:!w-20 p-2 rounded-md border border-border-color cursor-pointer"
                  onClick={handleMenuToggle}
                >
                  <BsDownload size={16} className="text-logo-color" />
                </div>

                {isMenuOpen && chartType !== undefined && (
                  <div className="absolute w-24 mt-1 top-full right-0 bg-sideheadbar-color rounded-sm shadow-md border border-border-color">
                    {/* Buraya tıklandığında açılacak seçenekleri ekleyin */}
                    <div
                      onClick={() =>
                        savePng(
                          chartType.value,
                          theme === "dark" ? "#171717" : "white"
                        )
                      }
                      className="py-2 px-4 rounded-sm cursor-pointer hover:bg-component-color"
                    >
                      Png
                    </div>
                    <div
                      onClick={() =>
                        chartType &&
                        saveAsPdf(
                          chartType.value,
                          theme === "dark" ? "#171717" : "white"
                        )
                      }
                      className="py-2 px-4 rounded-sm cursor-pointer  hover:bg-component-color"
                    >
                      Pdf
                    </div>

                    {/* İstediğiniz kadar seçenek ekleyebilirsiniz */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {chartType &&
          chartType.value === "LineChart" &&
          values.length >= 1 &&
          values.length === data.length && (
            <div className="flex flex-1 w-full h-full p-2 border-border-color border-[1px] rounded-md overflow-y-scroll">
              {isLoading ? (
                <div class="flex items-center space-x-2 self-center justify-center flex-1 ">
                  <div aria-label="Loading..." role="status">
                    <svg
                      width="24"
                      height="24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      xmlns="http://www.w3.org/2000/svg"
                      class="animate-spin w-8 h-8 stroke-table-head"
                    >
                      <path d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12"></path>
                    </svg>
                  </div>
                  <span class="text-lg font-medium text-table-head">
                    {t('loading.loading')}
                  </span>
                </div>
              ) : (
                <MultiLineChart
                  values={values}
                  theme={theme}
                  interval={interval}
                  datasets={data}
                  date={date}
                  colors={colors}
                  backgroundColors={backgroundColors}
                />
              )}
            </div>
          )}
        {chartType &&
          chartType.value === "BarChart" &&
          values.length >= 1 &&
          values.length === data.length && (
            <div className="flex flex-1 w-full h-full p-2 border-border-color border-[1px] rounded-md overflow-y-scroll">
              {isLoading ? (
                <div class="flex items-center space-x-2 self-center justify-center flex-1 ">
                  <div aria-label="Loading..." role="status">
                    <svg
                      width="24"
                      height="24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      xmlns="http://www.w3.org/2000/svg"
                      class="animate-spin w-8 h-8 stroke-slate-500"
                    >
                      <path d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12"></path>
                    </svg>
                  </div>
                  <span class="text-lg font-medium text-slate-500">
                    {t('chart.loading')}
                  </span>
                </div>
              ) : (
                <MultiBarChart
                  values={values}
                  theme={theme}
                  datasets={data.slice(0, values.length)}
                  date={date}
                  colors={colors}
                  backgroundColors={backgroundColors}
                />
              )}
            </div>
          )}
        {chartType &&
          chartType.value === "ScatterChart" &&
          values.length >= 1 &&
          values.length === data.length && (
            <div className="flex flex-1 w-full h-full p-2 border-border-color border-[1px] rounded-md overflow-y-scroll">
              {isLoading ? (
                <div class="flex items-center space-x-2 self-center justify-center flex-1 ">
                  <div aria-label="Loading..." role="status">
                    <svg
                      width="24"
                      height="24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      xmlns="http://www.w3.org/2000/svg"
                      class="animate-spin w-8 h-8 stroke-slate-500"
                    >
                      <path d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12"></path>
                    </svg>
                  </div>
                  <span class="text-lg font-medium text-slate-500">
                    {t('chart.loading')}
                  </span>
                </div>
              ) : (
                <MultiScatterChart
                  values={values}
                  theme={theme}
                  datasets={data.slice(0, values.length)}
                  date={date}
                  colors={colors}
                  backgroundColors={backgroundColors}
                />
              )}
            </div>
          )}
        {!chartType && (
          <div className="flex flex-1 items-center justify-center text-gray-500">
            {t('chart.select-chart')}
          </div>
        )}
      </div>
    </div>
  );
};

export default Chart;
