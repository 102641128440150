import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

export function MultiAreaChart({ datasets, date, theme, values, colors }) {


    const [displayYAxes, setDisplayYAxes] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const handleResize = () => {
            setDisplayYAxes(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const yAxes = datasets.map((dataset, index) => ({
        type: 'linear',
        display: displayYAxes,
        position: index % 2 === 0 ? 'left' : 'right',
        id: `y-axis-${index + 1}`,
        scaleLabel: {
            display: true,
            labelString: values[index].label,
            fontColor: colors[index],
            fontSize: 14,
        },
        zeroLineColor: 'red',
        title: {
            display: true,
            text: values[index].label,
            color: colors[index]
        },
        ticks: {
            beginAtZero: true,
            stepSize: 20,
            maxTicksLimit: 20,
            fontColor: '#3E3E3E',
            count: 20,
            color: theme === "dark" ? "#C2C2C2" : "black"

        },
        grid: {
            display: true,
            color: theme === "dark" ? '#3E3E3E' : "#C2C2C2",
            drawOnChartArea: false,
        },
        max: Math.max(...dataset) * 1.01,
        border: {
            color: theme === "dark" ? "#3E3E3E" : "#C2C2C2",
          },
    }));

    const data = {
        labels: date,
        datasets: datasets.map((dataset, index) => ({
            fill: true,
            label: values[index].label,
            data: dataset,
            borderColor: colors[index],
            backgroundColor:colors[index],
            pointRadius: 3,
            borderWidth: 2,
            yAxisID: `y-axis-${index + 1}`,
        })),
    };

    const scales = {
        x: {
            type: 'category',
            display: true,
            grid: {
                display: true,
                color: theme === "dark" ? '#3E3E3E' : "#C2C2C2",
            },
            ticks: {
                beginAtZero: true,
                color: theme === "dark" ? "#C2C2C2" : "black",
            },
            title: {
                color: theme === "dark" ? "#C2C2C2" : "black"
            }

        },
    };

    yAxes.forEach((yAxis) => {
        scales[yAxis.id] = yAxis;
    });

    return (
        <Line
            id='AreaChart'
            options={{
                color: theme === "dark" ? "white" : "black",
                maintainAspectRatio: false,
                responsive: true,
                interaction: {
                    intersect: false,
                    mode: 'index',
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            color: theme === "dark" ? "#C2C2C2" : "black",
                            usePointStyle: true,
                            fontSize: 12,
                        },
                        onClick: () => {
                            return null;
                        }
                    },
                },
                scales
            }}
            data={data}
        />
    );
}
