import React, { useEffect, useState } from "react";
import { FiUser } from "react-icons/fi";
import { RiLockPasswordLine } from "react-icons/ri";
import { useAuth } from "../providers/AuthProvider";
import { Navigate, useNavigate } from "react-router-dom";

const Login = () => {
  const [input, setInput] = useState({
    username: "",
    password: "",
  });

  const auth = useAuth();

  const handleSubmitEvent = (e) => {
    e.preventDefault();
    if (input.username !== "" && input.password !== "") {
      auth.loginAction(input);
     
      return;
    }
    alert("pleae provide a valid input");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));

  };
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.token) {
      navigate("/", { replace: true }); // Yönlendirme işlemi
    }
  }, [auth.token]);


  return (
    <form onSubmit={handleSubmitEvent} className="bg-background-color text-text-color h-screen w-screen flex flex-row">
      <div className="w-1/2 lg:flex h-full hidden items-center justify-end">
        <div className="w-full h-[80%] flex items-center justify-center border-r-2 border-table-head">
          <img
            alt="logo"
            src={require("../assets/logo.png")}
            className="w-60"
          />
        </div>
      </div>
      <div className="lg:w-1/2 w-full h-full flex items-center justify-center">
        <div className="sm:w-[60%] w-full h-[80%] lg:h-[60%] flex flex-col justify-around">
          <img
            alt="logo"
            src={require("../assets/logo.png")}
            className="w-40 lg:hidden block self-center"
          />
          <div className="text-4xl text-center tracking-wide">Welcome</div>
          <div className="flex flex-col space-y-5">
            <div className="flex flex-row h-12 w-[80%] self-center rounded-sm items-center gap-2 bg-table-head px-2  shadow-md">
              <FiUser size={20} />
              <input
                type="username"
                id="username"
                name="username"
                placeholder="username"
                aria-describedby="username"
                aria-invalid="false"
                onChange={handleInput}
                className="bg-table-head w-full focus:outline-none outline-none px-1"
              />
            </div>
            <div className="flex flex-row h-12 w-[80%] self-center rounded-sm items-center gap-2 bg-table-head px-2 shadow-md">
              <RiLockPasswordLine size={20} />
              <input
                 type="password"
                 id="password"
                 name="password"
                 placeholder="password"
                 aria-describedby="user-password"
                 aria-invalid="false"
                 onChange={handleInput}
                 
                className=" bg-table-head w-full  focus:outline-none outline-none px-1"
              />
            </div>

            <div className="flex flex-col space-y-1">
            <button className="bg-logo-color w-[60%] h-12 self-center text-black rounded-sm font-medium hover:opacity-80  shadow-md">
              Login now
            </button>
            
          </div>
          </div>

     
          <div className="text-center text-red-400">{auth.error}</div>
    
        </div>
      </div>
    </form>
  );
};

export default Login;
