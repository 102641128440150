import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./tableStyle.css";
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import { BsDownload } from "react-icons/bs";
import { downloadExcel } from "../../utils/downloadTable";
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "../../providers/AuthProvider";


const NotificationTable = () => {
  const { t } = useTranslation();


  const columns = [




    { id: "datetime", label: t('table.table-date'), minWidth: 150 },
    { id: "error", label: t('table.table-error'), minWidth: 150 },

  ];

  const [data, setData] = useState([])
  const auth = useAuth();

  const getNotifications = async () => {

    try {
      const response = await fetch(`https://dashboard.thermosoft.com.tr/api/notification`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json", // JSON içeriği göndermek için Content-Type'ı belirt
        },
      });

      // İsteğin başarılı olup olmadığını kontrol et
      if (response.ok) {
      

        const data = await response.json();
        setData(data);
      } else {
        // Başarısız yanıt alındıysa hata fırlatılabilir veya işlenebilir
        throw new Error("Kullanıcı oluşturma başarısız");
      }
    } catch (error) {
      // Hata durumunda konsola yazdır
      console.error("Bir hata oluştu:", error);

    }

  };

  useEffect(() => {
    getNotifications();
  }, [])

  return (

    <Paper
      className="!bg-background-color relative"
      sx={{ width: "100%", overflow: "hidden", fontPalette: "dark" }}
    >
      <TableContainer sx={{ maxHeight: "100%" }}>
        <Table
          className="!text-text-color"
          stickyHeader
          aria-label="sticky table"
        >

          <TableHead>
            <TableRow>
              {columns.map((column) =>
                column.id === "hour" ? (
                  <TableCell className="!bg-table-head !text-text-color !border-background-color !border-b-8  !text-xs lg:!text-sm  !py-1">
                    <div
                      onClick={() => downloadExcel(data)}
                      className="flex-1 flex items-center justify-center h-8 rounded-md  cursor-pointer"
                    >
                      <BsDownload className="text-logo-color text-2xl 3xl:text-5xl " />
                    </div>
                  </TableCell>
                ) : (
                  <TableCell
                    key={column.id}
                    align={"left"}
                    style={{ minWidth: column.minWidth }}
                    className="!bg-table-head !text-text-color !border-background-color !border-b-8  !text-xs lg:!text-sm 3xl:!text-3xl !py-3"
                  >
                    {column.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((row) => {
              return (
                <TableRow
                  className="!bg-sideheadbar-color hover:!bg-border-color"
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                >
                  {columns.map((column, index) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        className="!text-text-color !border-background-color !border-b-8 !text-xs lg:!text-sm 3xl:!text-2xl !py-2"
                        key={column.id}
                        align={"left"}
                      >
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default NotificationTable;