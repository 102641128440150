import React, { useEffect, useLayoutEffect, useState } from "react";
import Select from "react-select";
import "./ChartTypeAndIntervalSelectBox.css";
import { useDispatch, useSelector } from "react-redux";
import { setInterval } from "../../redux/selectedOptionsSlice";
import { useTranslation } from 'react-i18next';

const IntervalSelectBox = () => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState();
  const dispatch = useDispatch();
  const dateRange = useSelector((state) => state.selectedOptions.date);

  const timeDifferenceInMillis = dateRange
    ? new Date(dateRange[1]) - new Date(dateRange[0])
    : 0;

  const millisecondsInOneDay = 24 * 60 * 60 * 1000;

  const days = Math.floor(timeDifferenceInMillis / millisecondsInOneDay);
  
  const isOptionDisabled = (option) => {
    if (days === 0) {
      return option.value !== "hourly" && option.value !== "minutely";
    } else if (days < 7) {
      return option.value !== "hourly" && option.value !== "daily";
    } else if (days < 31) {
      return (
        option.value !== "hourly" &&
        option.value !== "daily" &&
        option.value !== "weekly"
      );
    } else {
      return (
        option.value !== "monthly" &&
        option.value !== "daily" &&
        option.value !== "weekly"
      );
    }
  };


  const options = [
   // { value: "minutely", label: t("chart.minute") },
    { value: "hourly", label: t("chart.hour") },
    { value: "daily", label: t("chart.day") },
    { value: "weekly", label: t("chart.week") },
    { value: "monthly", label: t("chart.month") },
  ];
  


  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    dispatch(setInterval(selectedOption));
  }, [selectedOption]);

  return (
    <Select
      isSearchable={false}
      name="colors"
      value={selectedOption}
      options={options}
      placeholder={t('chart.select')}
      onChange={handleChange}
      className="react-select-container"
      classNamePrefix="react-select"
      theme={(theme) => ({
        ...theme,
        colors: {
          primary: "black",
        },
      })}
      styles={{
        valueContainer: (base) => ({
          ...base,
          flex: 1,
          minHeight: "100%",
          borderRadius: 10,
          alignSelf: "stretch",
          padding: 3,
        }),
        menuList: (base) => ({
          ...base,
          boxShadow: "none",
          "::-webkit-scrollbar": {
            width: "10px",
            height: "0px",
          },
          "::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            backgroundColor: "#535353",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "20px",
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: "#9A9A9A",
          },
        }),
        control: (provided, state) => ({
          ...provided,
          boxShadow: "none",
        }),
        menu: () => ({
          borderRadius: 1,
        }),
        clearIndicator: (provided) => ({
          ...provided,
          "&:hover": {
            color: "orange",
          },
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "component",
          borderRadius: 2,
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          svg: {
            fill: "#FFB500",
          },
        }),

        indicatorSeparator: (provided) => ({
          ...provided,
          backgroundColor: "#FFB500",
        }),
      }}
      isClearable={false}
      isOptionDisabled={isOptionDisabled}
    />
  );
};

export default IntervalSelectBox;
