import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addModalOpen: false,
  deleteModalOpen: false,
  updateModalOpen: false,
  userId: null,
};

export const modalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setAddModalOpen: (state, action) => {
      state.addModalOpen = action.payload;
    
    },
    setDeleteModalOpen: (state, action) => {
      state.deleteModalOpen = action.payload.value;
      state.userId = action.payload.id;
    },
    setUpdateModalOpen: (state, action) => {
      state.updateModalOpen = action.payload.value;
      state.userId = action.payload.id;
    },
  },
});

export const { setAddModalOpen, setDeleteModalOpen, setUpdateModalOpen } =
  modalSlice.actions;

export default modalSlice.reducer;
