import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../providers/AuthProvider';

const WorkplaceSelector = () => {
    const [workplaces, setWorkplaces] = useState([]);
    const [selectedWorkplace, setSelectedWorkplace] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { token, setCompany } = useAuth();
    const dropdownRef = useRef(null);

    useEffect(() => {
        fetch('https://dashboard.thermosoft.com.tr/api/company/companies', {
            method: 'GET',
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        })
            .then(response => response.json())
            .then(data => {
                setWorkplaces(data);
                setSelectedWorkplace(data[0]); // Varsayılan olarak ilk workplace seçilir
            })
            .catch(error => {
                console.error("Error fetching workplaces", error);
            });
    }, []);

    const handleSelect = (workplace) => {
        setSelectedWorkplace(workplace);
        setCompany(workplace.id);
        setIsDropdownOpen(false); // Dropdown'u kapat
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false); // Dropdown'u kapat
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative inline-block" ref={dropdownRef}>
            {/* Seçilen logo */}
            <div className="p-2 overflow-hidden border border-gray-300 cursor-pointer flex items-center justify-center rounded-2xl" onClick={toggleDropdown}>
                {selectedWorkplace && (
                    <div> <p className="w-full h-full object-cover hidden xl:flex">
                        {selectedWorkplace.company_name}
                    </p>
                        <p className='xl:hidden'>
                            {selectedWorkplace.company_name.charAt(0).toUpperCase()}
                        </p>
                    </div>

                )}
            </div>

            {/* Dropdown menü */}
            {isDropdownOpen && (
                <div className="fixed w-[12%] left-0 mt-2 bg-sideheadbar-color border-border-color border  shadow-lg rounded-lg z-50"
                >
                    {workplaces.map(workplace => (
                        <div
                            key={workplace.id}
                            className="flex items-center px-5 py-2 cursor-pointer  border-b border-border-color"
                            onClick={() => handleSelect(workplace)}
                        >
                            <span className="text-text-color ">{workplace.company_name}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default WorkplaceSelector;
