import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "./datetimePicker.css";
import "react-datepicker/dist/react-datepicker.css";

const DateTimePicker = ({ onChange, startDate, setStartDate }) => {


    const handleDateChange = (date) => {
        setStartDate(date); // Seçilen tarihi kendi local state'te güncelleyin
        onChange(date); // Tarihi üst bileşene iletin
    };

    return (
        <div className="w-[300px]">
            <ReactDatePicker
                selected={startDate}
                onChange={handleDateChange}
                timeInputLabel="Time:"
                showTimeSelect
                dateFormat="dd/MM/yyyy HH:mm"
                timeIntervals={1}
                className="bg-sideheadbar-color w-[300px] flex h-10  3xl:text-xl 4xl:text-3xl outline-none text-center px-2 border border-border-color text-text-color rounded-md"
            />
        </div>
    );
};

export default DateTimePicker;
