import { useEffect, useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Layout from "./shared/Layout";
import Dashboard from "./pages/Dashboard";
import Orc from "./pages/Orc";
import Bop from "./pages/Bop";
import Chart from "./pages/Chart";
import Prediction from "./pages/Prediction";
import Table from "./pages/Table";
import Administrator from "./pages/Administrator";
import Login from "./pages/Login";
import AuthProvider from "./providers/AuthProvider";
import PrivateRoute from "./shared/PrivateRoute";
import HelpCenter from "./pages/HelpCenter";

function App() {

  {/** 
  useEffect(() => {
    // Kopyalamayı ve sağ tıklama menüsünü kapatma
    const disableCopy = (e) => e.preventDefault();

    document.addEventListener('selectstart', disableCopy);
    document.addEventListener('contextmenu', disableCopy);

    // Temizleme işlemi
    return () => {
      document.removeEventListener('selectstart', disableCopy);
      document.removeEventListener('contextmenu', disableCopy);
    };
  }, []);
  */}
  useLayoutEffect(() => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.querySelector("html").classList.remove("light");
      document.querySelector("html").classList.add("dark");
    } else {
      document.querySelector("html").classList.remove("dark");
      document.querySelector("html").classList.add("light");
    }
  }, []);


  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/prediction" element={<Prediction />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/orc" element={<Orc />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/bop" element={<Bop />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/chart" element={<Chart />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/prediction" element={<Prediction />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/table" element={<Table />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/help" element={<HelpCenter />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/administrator" element={<Administrator />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
