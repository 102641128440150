import React, { useEffect, useState } from "react";
import { setChartType } from "../../redux/selectedOptionsSlice";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "./ChartTypeAndIntervalSelectBox.css";
import { useTranslation } from 'react-i18next';


const ChartTypeSelectBox = () => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState();
  const dispatch = useDispatch();

  const dateRange = useSelector((state) => state.selectedOptions.date);
  const values = useSelector((state) => state.selectedOptions.chartValues);
  const interval = useSelector((state) => state.selectedOptions.interval);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    dispatch(setChartType(selectedOption));
  }, [selectedOption]);

  const chartOptions = [
    { value: "LineChart", label:  t("chartType.line")  },
    { value: "BarChart", label: t("chartType.bar") },
    { value: "ScatterChart", label: t("chartType.scatter") },
  ];
  return (
    <Select
      isSearchable={false}
      name="colors"
      value={selectedOption}
      options={chartOptions}
      onChange={handleChange}
      placeholder={t('chart.select')}
      className="react-select-container"
      classNamePrefix="react-select"
      theme={(theme) => ({
        ...theme,
        colors: {
          primary: "black",
        },
      })}
      styles={{
        valueContainer: (base) => ({
          ...base,
          flex: 1,
          minHeight: "100%",
          borderRadius: 10,
          alignSelf: "stretch",
          padding: 3,
        }),
        menuList: (base) => ({
          ...base,
          boxShadow: "none",
          "::-webkit-scrollbar": {
            width: "10px",
            height: "0px",
          },
          "::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            backgroundColor: "#535353",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "20px",
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: "#9A9A9A",
          },
        }),
        control: (provided, state) => ({
          ...provided,
          boxShadow: "none",
        }),
        menu: () => ({
          borderRadius: 1,
        }),
        clearIndicator: (provided) => ({
          ...provided,
          "&:hover": {
            color: "orange",
          },
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "component",
          borderRadius: 2,
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          svg: {
            fill: "#FFB500",
          },
        }),

        indicatorSeparator: (provided) => ({
          ...provided,
          backgroundColor: "#FFB500",
        }),
      }}
      isClearable={false}
    />
  );
};

export default ChartTypeSelectBox;
