import React, { useEffect, useRef, useState } from "react";
import DatePicker from "../components/selectBoxes/DatePicker";
import { BsDownload } from "react-icons/bs";
import CustomTable from "../components/tables/CustomTable";
import { useSelector } from "react-redux";
import IntervalSelectBox from "../components/selectBoxes/IntervalSelectBox";
import { downloadAsCsv, downloadExcel } from "../utils/downloadTable";
import TableMultiSelectBox from "../components/selectBoxes/TableMultiSelectBox";
import { useTranslation } from 'react-i18next';

const colors = [
  "#2F82F9",
  "#FF5A5A",
  "#686DF7",
  "#8B9700",
  "#00B707",
  "#FF7E36",
];

const Table = () => {
  const { t } = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const [rows, setRows] = useState([]);
  const dateRange = useSelector((state) => state.selectedOptions.date);
  const values = useSelector((state) => state.selectedOptions.tableValues);
  const interval = useSelector((state) => state.selectedOptions.interval);
  const [headCells, setHeadCells] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrror, setIsError] = useState(false);

  useEffect(() => {
    const newHeadCells = values.map((item) => ({
      id: item.label,
      label: item.value,
    }));

    const datetimeItem = {
      id: "zaman_etiketi",
      label: "zaman_etiketi",
    };
    newHeadCells.unshift(datetimeItem);

    setHeadCells(newHeadCells);
  }, [values]);

  useEffect(() => {
    const getDataByDateRange = async () => {
      if (
        !dateRange ||
        !values ||
        !interval ||
        dateRange.length === 0 ||
        values.length === 0 ||
        !interval.value
      ) {
        return;
      }
      setIsLoading(true);
      const startDate = dateRange[0];
      const endDate = dateRange[1];

      try {
        
        const baseUrl = "https://dashboard.thermosoft.com.tr/api/historyorc";

        const columnNames = values.map((item) => item.value).join(",");
       
        const queryParams = new URLSearchParams();
        queryParams.append("startDate", startDate);
        queryParams.append("endDate", endDate);
        queryParams.append("interval", interval.value);
        queryParams.append("columnName", columnNames);

        const url = `${baseUrl}/selectedvalues?${queryParams.toString()}`;

        const response = await fetch(url);

        if (!response.ok) {
          setIsError(true);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const fetchedData = await response.json();
        const dataKeys = Object.keys(fetchedData);

        if (dataKeys.length > 0) {
          const newRows = await fetchedData[dataKeys[0]].map((item, index) => {
            const row = {};
            dataKeys.forEach((dataKey) => {
              row[dataKey.toLowerCase()] = fetchedData[dataKey][index];
            });

            return row;
          });

          setRows(newRows);
      
        } else {
          setIsError(true);
          console.error(
            "Received data is not in the expected format or is empty."
          );
        }
      } catch (error) {
        setIsError(true);
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getDataByDateRange();
  }, [values, dateRange, interval]);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col overflow-auto w-full h-full gap-3">
     
     {/* Select boxes  */}
      <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-around px-0.5  w-full gap-1 sm:gap-3 ">
        <div className="w-full h-10 3xl:!h-16 4xl:!h-20 z-40">
          <TableMultiSelectBox selectedColors={colors} />
        </div>
        <div className="flex flex-row xxs:flex-col gap-1 sm:gap-3 items-center">
          <div className="w-full h-10 3xl:!h-16 4xl:!h-20 z-30">
            <DatePicker />
          </div>
          <div className="w-full h-10 3xl:!h-16 4xl:!h-20 z-20  flex flex-row gap-1 sm:gap-3">
            <div className="flex-1 w-full h-10 z-30 3xl:!h-16 4xl:!h-20">
              <IntervalSelectBox />
            </div>
            <div
              className="relative flex h-10 3xl:!h-16 4xl:!h-20 z-1 text-text-color"
              ref={menuRef}
            >
              <div
                className="bg-sideheadbar-color flex items-center justify-center w-10 h-10 p-2 rounded-md border border-border-color cursor-pointer 3xl:!h-16 4xl:!h-20"
                onClick={handleMenuToggle}
              >
                <BsDownload size={16} className="text-logo-color" />
              </div>

              {isMenuOpen && (
                <div className="absolute w-24 mt-1 top-full right-0 bg-sideheadbar-color rounded-sm shadow-md border border-border-color">
                  {/* Buraya tıklandığında açılacak seçenekleri ekleyin */}
                  <div
                    onClick={() => downloadExcel(rows)}
                    className="py-2 px-4 rounded-sm cursor-pointer hover:bg-component-color"
                  >
                    Excel
                  </div>
                  <div
                    onClick={() => downloadAsCsv(rows)}
                    className="py-2 px-4 rounded-sm cursor-pointer  hover:bg-component-color"
                  >
                    Csv
                  </div>

                  {/* İstediğiniz kadar seçenek ekleyebilirsiniz */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isLoading && values.length>0 ? (
        <div class="flex items-center space-x-2 self-center justify-center flex-1 ">
          <div aria-label="Loading..." role="status">
            <svg
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              xmlns="http://www.w3.org/2000/svg"
              class="animate-spin w-8 h-8 stroke-slate-500"
            >
              <path d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12"></path>
            </svg>
          </div>
          <span class="text-lg font-medium text-slate-500">Loading...</span>
        </div>
      ) : (
        values.length>0 &&
        dateRange &&
        interval &&
        !isErrror && (
          <div className="bg-sideheadbar-color flex flex-1 overflow-hidden !rounded-md">
            <CustomTable values={headCells} rows={rows} />
          </div>
        )
      )}

      {(!interval || !dateRange || values.length===0 ) && (
        <div className="flex flex-1 items-center justify-center text-gray-500">
         {t('table.table-desc')}
        </div>
      )}
      
    </div>
  );
};

export default Table;
