import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { colors } from "../../consts/colors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ElectricityConsumptionsBarChart = ({
  theme,
  title,
  xAxisLabel,
  titleValue1,
  titleValue2,
  datas
}) => {
  const data = {
    labels: xAxisLabel,
    datasets: [
      {
        label: titleValue1,
        data: datas,
        borderColor: "rgb(255, 181, 0)",
        backgroundColor: "rgba(255, 181, 0, 0.5)",
        borderWidth: 1,
      },
    ],
  };

  const color =
    theme === "dark"
      ? colors.chart.dark["font-color"]
      : colors.chart.light["font-color"];
  const gridColor =
    theme === "dark"
      ? colors.chart.dark["grid-color"]
      : colors.chart.light["grid-color"];

  return (
    <Bar
      options={{
        responsive: true,
        color: color,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
            display: false,
          },
          title: {
            display: true,
            text: title,
            color: color,
          },
        },
        scales: {
          x: {
            axis: "x",
            ticks: {
              color: color,
              align: "start",
            },
            grid: {
              display: true,
              tickColor: color,
              drawOnChartArea: true,
              color: gridColor,
            },
            border: {
              color: color,
              display: true,
            },
          },
          y: {
            axis: "y",
            ticks: {
              color: color,
            },
            grid: {
              display: true,
              tickColor: color,
              drawOnChartArea: true,
              color: gridColor,
            },
            border: {
              color: color,
            },
          },
        },
      }}
      data={data}
    />
  );
};

export default ElectricityConsumptionsBarChart;
