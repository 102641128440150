import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(sessionStorage.getItem("token") || "");
  const [userRole, setUserRole] = useState("");
  const [error, setError] = useState("");
  const [company,setCompany]=useState()

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      // JWT'den kullanıcı rolünü çıkarmak için token'ı decode et
      const decodeToken = (token) => {
        const base64Url = token.split('.')[1];
        const base64 = decodeURIComponent(atob(base64Url).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
        return JSON.parse(base64);
      };

      const decodedToken = decodeToken(token);
      setUserRole(decodedToken.role);
      setCompany(decodedToken.company);
    }
  }, [token]);

  const loginAction = async (data) => {
    try {
      const response = await fetch("https://dashboard.thermosoft.com.tr/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      if (res.token) {
        setToken(res.token);
        sessionStorage.setItem("token", res.token);
        // Token'dan rol bilgisini çıkarıp state'e set et
        const decodedToken = decodeToken(res.token);
        setUserRole(decodedToken.role);
        setCompany(decodedToken.company);
        navigate("/");
      } else {
        setError("Kullanıcı adı veya şifre yanlış");
      }
    } catch (err) {
      setError("Kullanıcı adı veya şifre yanlış");
    }
  };

  const logOut = async () => {
    try {
      const response = await fetch("https://dashboard.thermosoft.com.tr/api/logout", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
  
        setToken("");
        setUserRole("");
        setCompany(null);
        sessionStorage.removeItem("token");
        navigate("/login");
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  // JWT'den kullanıcı rolünü çıkarmak için kullanılan fonksiyon
  const decodeToken = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = decodeURIComponent(atob(base64Url).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
    return JSON.parse(base64);
  };

  return (
    <AuthContext.Provider value={{ token, userRole, loginAction, logOut, error ,company,setCompany}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
