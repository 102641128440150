export const colors = {
    "logo-color": "#FFC700",
    "border-color": "#2F3640",
    chartBackgroundColors:  [
        "rgba(33, 150, 243, 0.5)",
        "rgba(30, 136, 229, 0.5)",
        "rgba(25, 118, 210, 0.5)",
        "rgba(21, 101, 192, 0.5)",
        "rgba(13, 71, 161, 0.5)",
        "rgba(10, 76, 143, 0.5)",
        "rgba(8, 45, 81, 0.5)",
        "rgba(130, 177, 255, 0.5)",
        "rgba(68, 138, 255, 0.5)",
        "rgba(41, 121, 255, 0.5)",
        "rgba(41, 98, 255, 0.5)",
        "rgba(0, 51, 255, 0.5)"
      ],
    chartBackgroundColors2: [
        "rgba(255, 183, 77, 0.5)",
        "rgba(255, 167, 38, 0.5)",
        "rgba(255, 152, 0, 0.5)",
        "rgba(251, 140, 0, 0.5)",
        "rgba(245, 124, 0, 0.5)",
        "rgba(239, 108, 0, 0.5)",
        "rgba(230, 81, 0, 0.5)",
        "rgba(255, 209, 128, 0.5)",
        "rgba(255, 171, 64, 0.5)",
        "rgba(255, 145, 0, 0.5)",
        "rgba(255, 109, 0, 0.5)",
        "rgba(255, 87, 34, 0.5)"
      ],
    "chart": {
        "dark": {
            "grid-color":"#3D3D3D",
            "font-color":"#D3D3D3"
        },
        "light":{
            "grid-color":"#E1E1E1",
            "font-color":"#424242"
        }
    }
}
