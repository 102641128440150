import { configureStore } from '@reduxjs/toolkit'
import modalSlice from './modalSlice'
import selectedOptionsSlice from './selectedOptionsSlice'
import langSlice from './langSlice'
export const store = configureStore({
  reducer: {
    modals: modalSlice,
    selectedOptions:selectedOptionsSlice,
    checkLang: langSlice,
  },
})