import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateModalOpen } from "../redux/modalSlice";
import { useAuth } from "../providers/AuthProvider";

const UpdateModal = () => {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.modals);
  const closeModal = () => {
    dispatch(setUpdateModalOpen(false));
  };
  const auth = useAuth();

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    username: "",
    phone_number: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const response = await fetch(
        `https://dashboard.thermosoft.com.tr/api/users/byid/${userId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      // Check if the request was successful
      if (response.ok) {
        const userData = await response.json();
        // Merge the received data with the existing formData
        setFormData((prevData) => ({
          ...prevData,
          ...userData,
        }));
      } else {
        // Handle the case of unsuccessful response, you can throw an error or process it accordingly
        throw new Error("Failed to fetch user data");
      }
    } catch (error) {
      // Log the error to console in case of failure
      console.error("An error occurred:", error);
    }
  };

  const updateUser = async () => {


    try {
      const response = await fetch(
        `https://dashboard.thermosoft.com.tr/api/users/update/${userId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData), // Stringify the formData
        }
      );

      if (response.ok) {
       
      } else {
        // Handle errors if any
        console.error("Failed to update user");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("An error occurred:", error);
    }
    closeModal(); // Close modal regardless of success or failure
  };

  return (
    <div
      className={`flex fixed items-center justify-center top-0 left-0 right-0 z-50  p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}
    >
      <div className="w-[800px] h-[600px] border border-component-color rounded-md bg-background-color flex sm:gap-3 justify-around items-center flex-col p-3 relative">
        <h3 className="text-text-color font-semibold md:text-2xl">Edit User</h3>

        <button
          onClick={closeModal}
          type="button"
          class="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <CgClose size={28} />
        </button>
        <div className="h-[0.1px] bg-border-color w-full" />
        <div className="flex w-full px-5 py-3 lg:px-8 items-start gap-2 sm:gap-7 flex-wrap justify-around">
          <div className="flex w-full sm:w-[45%] flex-col justify-center gap-1">
            <label className="text-text-color text-sm">Name</label>
            <input
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter name"
              className="text-sm rounded-sm outline-none focus:outline-1 focus:outline-border-color  block w-full p-2.5 sm:p-3 text-text-color bg-table-head"
            />
          </div>
          <div className="flex w-full sm:w-[45%] flex-col justify-center gap-1">
            <label className="text-text-color text-sm">Surname</label>
            <input
              name="surname"
              value={formData.surname}
              onChange={handleChange}
              placeholder="Enter surname"
              className="text-sm rounded-sm outline-none focus:outline-1 focus:outline-border-color  block w-full p-2.5 sm:p-3 text-text-color bg-table-head"
            />
          </div>
          <div className="flex w-full sm:w-[45%] flex-col justify-center gap-1">
            <label className="text-text-color text-sm">Username</label>
            <input
              name="username"
              value={formData.username}
              onChange={handleChange}
              placeholder="Enter username"
              className="text-sm rounded-sm outline-none focus:outline-1 focus:outline-border-color  block w-full p-2.5 sm:p-3 text-text-color bg-table-head"
            />
          </div>
          <div className="flex w-full sm:w-[45%] flex-col justify-center gap-1">
            <label className="text-text-color text-sm">Phone number</label>
            <input
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              placeholder="Enter phone number"
              className="text-sm rounded-sm outline-none focus:outline-1 focus:outline-border-color  block w-full p-2.5 sm:p-3 text-text-color bg-table-head"
            />
          </div>
          <div className="flex w-full sm:w-[45%] flex-col justify-center gap-1">
            <label className="text-text-color text-sm">Email</label>
            <input
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter email"
              className="text-sm rounded-sm outline-none focus:outline-1 focus:outline-border-color  block w-full p-2.5 sm:p-3 text-text-color bg-table-head"
            />
          </div>
          <div className="flex w-full sm:w-[45%] flex-col justify-center gap-1">
            <label className="text-text-color text-sm">Password</label>
            <input
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter password"
              className="text-sm rounded-sm outline-none focus:outline-1 focus:outline-border-color  block w-full p-2.5 sm:p-3 text-text-color bg-table-head"
            />
          </div>
        </div>

        <button
          onClick={updateUser}
          className=" bg-[#40C956]  w-1/3 p-1 sm:p-2 text-black md:text-xl font-semibold rounded-sm transition duration-200 hover:opacity-70"
        >
          <p className="text-text-color text-xl font-semibold">Edit</p>
        </button>
      </div>
    </div>
  );
};

export default UpdateModal;
