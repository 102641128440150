import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";

const Layout = ({ children }) => {
  return (
    <div className="h-screen w-screen flex flex-col bg-background-color">
      <Header />
      <div className="w-full h-full flex flex-1 flex-row bg-background-color overflow-auto ">
        <div className="h-full overflow-visible">
          <Sidebar />
        </div>
        {
          // landscape:min-h-[500px]
        }
        <div className="h-full p-3 bg-background-color w-full overflow-x-auto overflow-y-visible">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
