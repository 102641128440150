import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   checkLanguage: false
};

export const langSlice = createSlice({
    name: "lang",
    initialState,
    reducers: {
        toggleLang: (state) => {
            state.checkLanguage = !state.checkLanguage;
            
        },
    },
});

export const { toggleLang } = langSlice.actions;
export default langSlice.reducer;
