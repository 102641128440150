import React, { useState } from 'react';
import { useAuth } from '../providers/AuthProvider';

const HelpCenter = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [comments, setComments] = useState('');
    const [file, setFile] = useState(null);
    const { token } = useAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('subject', subject);
        formData.append('comments', comments);
        if (file) {
            formData.append('file', file);
        }

        try {
            const response = await fetch('https://dashboard.thermosoft.com.tr/api/mail', {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.ok) {
                alert('Şikayetiniz başarıyla gönderildi!');
            } else {
                alert('Şikayet gönderilirken bir hata oluştu.');
            }
        } catch (error) {
            console.error('Şikayet gönderilirken bir hata oluştu:', error);
            alert('Şikayet gönderilirken bir hata oluştu.');
        }
    };

    return (
        <div className="flex items-center flex-col h-[80%] space-y-6">
            <div className="text-text-color text-lg mt-6">Contact the Help Team</div>
            <form onSubmit={handleSubmit} className="w-[80%] max-w-[500px] flex flex-col space-y-2 ">
                <div className="flex flex-col space-y-1">
                    <div className="text-text-color">Name</div>
                    <input
                        className="w-full p-2 rounded-sm outline-0 bg-sideheadbar-color text-text-color border border-border-color"
                        type="text"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <div className="text-text-color">Email</div>
                    <input
                        className="w-full p-2 rounded-sm outline-0 bg-sideheadbar-color text-text-color border border-border-color"
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <div className="text-text-color">Subject</div>
                    <input
                        className="w-full p-2 rounded-sm outline-0 bg-sideheadbar-color text-text-color border border-border-color"
                        type="text"
                        placeholder="Enter subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <div className="text-text-color">Comments</div>
                    <textarea
                        rows="5"
                        className="w-full p-2 rounded-sm outline-0 bg-sideheadbar-color text-text-color border border-border-color line-clamp-6"
                        placeholder="Enter your comments"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                    />
                </div>
                <div className='flex md:flex-row flex-col space-between gap-3'>
                    <div className="flex flex-col space-y-1">
                        <div className="text-text-color">Attachment</div>
                        <input
                            type="file"
                            onChange={(e) => setFile(e.target.files[0])}
                            className='text-text-color'
                        />
                    </div>
                    <button type="submit" className="bg-logo-color w-[200px] p-2 rounded-sm self-center">
                        Send email
                    </button>
                </div>
            </form>
        </div>
    );
}

export default HelpCenter;
