import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { setDeleteModalOpen } from "../redux/modalSlice";
import { RiErrorWarningLine } from "react-icons/ri";
import { useAuth } from "../providers/AuthProvider";

const DeleteModal = () => {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.modals);
  const auth = useAuth();
  const closeModal = () => {
    dispatch(setDeleteModalOpen(false));
  };

  const deleteUser = async () => {
    
    try {
      const response = await fetch("https://dashboard.thermosoft.com.tr/api/users/delete", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json", // JSON içeriği göndermek için Content-Type'ı belirt
        },
        body: userId,
      });

      // İsteğin başarılı olup olmadığını kontrol et
      if (response.ok) {
      
      } else {
        // Başarısız yanıt alındıysa hata fırlatılabilir veya işlenebilir
        throw new Error("Kullanıcı oluşturma başarısız");
      }
    } catch (error) {
      // Hata durumunda konsola yazdır
      console.error("Bir hata oluştu:", error);
    }
    closeModal();
  };

  return (
    <div
      className={`flex fixed items-center justify-center top-0 left-0 right-0 z-50 bg-border-color bg-opacity-5  p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-ful`}
    >
      <div className="w-[500px] h-[250px] rounded-md bg-background-color border border-border-color flex sm:gap-3 justify-around items-center flex-col p-3 relative">
        <button
          onClick={closeModal}
          type="button"
          class="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <CgClose size={28} />
        </button>

        <RiErrorWarningLine className="fill-text-color text-5xl md:text-6xl" />
        <p className="md:text-lg text-text-color">
          Are you sure you want to delete this user?
        </p>

        <div className="flex items-center justify-around">
          <button
            onClick={deleteUser}
            data-modal-hide="popup-modal"
            type="button"
            class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-2 md:px-5 py-2.5 text-center mr-2"
          >
            Yes, I'm sure
          </button>
          <button
            onClick={closeModal}
            data-modal-hide="popup-modal"
            type="button"
            class="text-gray-500
                     bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border
                      border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10
                       dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white 
                       dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          >
            No, cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
