import * as XLSX from "xlsx/xlsx.mjs";
import { saveAs } from "file-saver";

const downloadExcel = (data) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const dataBlob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(dataBlob, "table_data.xlsx");
};

const downloadAsCsv = (data) => {

  if (!data || data.length === 0) {
    return;
  }
  
  const headers = Object.keys(data[0]);
  const csvContent =
    headers.join(",") +
    "\n" +
    data
      .map((row) => headers.map((header) => row[header]).join(","))
      .join("\n");

  const dataBlob = new Blob([csvContent], { type: "text/csv" });
  saveAs(dataBlob, "file.csv");
};

export { downloadExcel, downloadAsCsv };
