const photoMap = {
  orc: {
    1: {
      dark: require('../assets/orc-dark.png'),
      light: require('../assets/orc-white.png'),
    },
    2: {
      dark: require('../assets/orc-dark.png'),
      light: require('../assets/orc-white.png'),
    },
  },
    bop: {
      1: {
        dark: require('../assets/bop-dark.png'),
        light: require('../assets/bop-white.png'),
      },
      2: {
        dark: require('../assets/BOP-PAGE.png'),
        light: require('../assets/BOP-PAGE.png'),
      },
  }
};


export default photoMap;