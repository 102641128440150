import { saveAs } from "file-saver";
import jsPDF from "jspdf";



const savePng =  (id, backgroundColor) => {
    const canvasSave = document.getElementById(id);
  
    if (canvasSave) {
      const base64Image = canvasSave.toDataURL('image/png');
  
      const image = new Image();
  
      image.src = base64Image;
  
      image.onload = function() {
        const canvasDownload = document.createElement('canvas');
        const context = canvasDownload.getContext('2d');
  
        canvasDownload.width = image.width;
        canvasDownload.height = image.height;
  
        context.fillStyle = backgroundColor;
        context.fillRect(0, 0, canvasDownload.width, canvasDownload.height);
  
        context.drawImage(image, 0, 0);
  
        saveAs(canvasDownload.toDataURL('image/png'), 'chart_with_background.png');
      };
    } else {
      console.error('Canvas element not found');
    }
  };
  

function downloadPDF(id) {
    var canvas = document.getElementById(id);

    if (canvas) {
        var canvasImg = canvas.toDataURL("image/png");
        var doc = new jsPDF('landscape');
        doc.setFontSize(20);
        doc.addImage(canvasImg, 'png', 10, 10, 280, 150);
        doc.save('canvas.pdf');
    }
    else {
     
    }
}

function saveAsPdf(id, backgroundColor) {
    var canvas = document.getElementById(id);
    var canvasImg = canvas.toDataURL("image/png", 1.0);

    var doc = new jsPDF("landscape");

    if (backgroundColor) {
        doc.setFillColor(backgroundColor);
        doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, 'F');
    }

    doc.addImage(canvasImg, 'png', 10, 10, 280, 150);

    doc.save('sample.pdf');
}




export { savePng, downloadPDF ,saveAsPdf}