import React, { useEffect, useState } from "react";
import { setAddModalOpen } from "../redux/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { CgClose } from "react-icons/cg";
import { useAuth } from "../providers/AuthProvider";

const AddModal = () => {
  const dispatch = useDispatch();
  const addModalOpen = useSelector((state) => state.modals.addModalOpen);
  const { token,company } = useAuth();
  const closeModal = () => {
    dispatch(setAddModalOpen(false));
  };

  const [userData, setUserData] = useState({
    name: "",
    surname: "",
    username: "",
    phone_number: "",
    email: "",
    password: "",
    role: "USER",
    company_id: company,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const createUser = async () => {
 
    try {
      const response = await fetch(`https://dashboard.thermosoft.com.tr/api/users/create?companyId=${company}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // JSON içeriği göndermek için Content-Type'ı belirt
        },
        body: JSON.stringify(userData),
      });

      // İsteğin başarılı olup olmadığını kontrol et
      if (response.ok) {
       
      } else {
        // Başarısız yanıt alındıysa hata fırlatılabilir veya işlenebilir
        throw new Error("Kullanıcı oluşturma başarısız");
      }
    } catch (error) {
      // Hata durumunda konsola yazdır
      console.error("Bir hata oluştu:", error);
 
    }
    closeModal();
  };



  return (
    <div
      className={`flex fixed items-center justify-center top-0 left-0 right-0 z-50  p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}
    >
      <div className="w-[800px] h-[600px] border border-component-color rounded-md duration-150 bg-background-color text-text-color flex sm:gap-3 justify-around items-center flex-col p-3 relative">
        <h3 className="text-text-color font-semibold md:text-2xl">
          Add New User
        </h3>

        <button
          onClick={closeModal}
          type="button"
          class="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <CgClose size={28} />
        </button>

        <div className="h-[0.1px] bg-border-color w-full" />
        <div className="flex w-full px-5 py-3 lg:px-8 items-start gap-2 sm:gap-7 flex-wrap justify-around">
          <div className="flex w-full sm:w-[45%] flex-col justify-center gap-1">
            <label className="text-text-color text-sm">Name</label>
            <input
              name="name"
              value={userData.name}
              onChange={handleInputChange}
              placeholder="Enter your name"
              className="text-sm rounded-sm outline-none focus:outline-1 focus:outline-border-color  block w-full p-2.5 sm:p-3 text-text-color bg-table-head"
            />
          </div>
          <div className="flex w-full sm:w-[45%] flex-col justify-center gap-1">
            <label className="text-text-color text-sm">Surname</label>
            <input
              name="surname"
              value={userData.surname}
              onChange={handleInputChange}
              placeholder="Enter your surname"
              className="text-sm rounded-sm outline-none focus:outline-1 focus:outline-border-color  block w-full p-2.5 sm:p-3 text-text-color bg-table-head"
            />
          </div>
          <div className="flex w-full sm:w-[45%] flex-col justify-center gap-1">
            <label className="text-text-color text-sm">Username</label>
            <input
              name="username"
              value={userData.username}
              onChange={handleInputChange}
              placeholder="Enter your username"
              className="text-sm rounded-sm outline-none focus:outline-1 focus:outline-border-color  block w-full p-2.5 sm:p-3 text-text-color bg-table-head"
            />
          </div>
          <div className="flex w-full sm:w-[45%] flex-col justify-center gap-1">
            <label className="text-text-color text-sm">Phone number</label>
            <input
              name="phone_number"
              value={userData.phone_number}
              onChange={handleInputChange}
              placeholder="Enter your phone number"
              className="text-sm rounded-sm outline-none focus:outline-1 focus:outline-border-color  block w-full p-2.5 sm:p-3 text-text-color bg-table-head"
            />
          </div>
          <div className="flex w-full sm:w-[45%] flex-col justify-center gap-1">
            <label className="text-text-color text-sm">Email</label>
            <input
              name="email"
              value={userData.email}
              onChange={handleInputChange}
              placeholder="Enter your email"
              className="text-sm rounded-sm outline-none focus:outline-1 focus:outline-border-color  block w-full p-2.5 sm:p-3 text-text-color bg-table-head"
            />
          </div>
          <div className="flex w-full sm:w-[45%] flex-col justify-center gap-1">
            <label className="text-text-color text-sm">Password</label>
            <input
              name="password"
              value={userData.password}
              onChange={handleInputChange}
              placeholder="Enter your password"
              className="text-sm rounded-sm outline-none focus:outline-1 focus:outline-border-color  block w-full p-2.5 sm:p-3 text-text-color bg-table-head"
            />
          </div>
        </div>

        <button
          onClick={createUser}
          className="w-1/3 p-1 sm:p-2 bg-logo-color text-black md:text-xl font-semibold rounded-sm transition duration-200 hover:opacity-70"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddModal;
