import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { TableFooter } from "@mui/material";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [headCells, setHeadCells] = useState([]);
  const values = useSelector((state) => state.selectedOptions.tableValues);

  useEffect(() => {
    const newHeadCells = values.map((item) => ({
      id: item.label.toLowerCase(),
      label: item.value,
    }));

    const datetimeItem = {
      id: "zaman_etiketi",
      label: "Datetime",
    };
    newHeadCells.unshift(datetimeItem);

    setHeadCells(newHeadCells);
  }, [values]);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            height={50}
            padding="normal"
            key={headCell.id}
            align={"center"}
            sortDirection={orderBy === headCell.id ? order : false}
            className="!bg-table-head !text-text-color !border-background-color !text-[10px] md:!text-xs"
            sx={{
              ".css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active ":
                {
                  color: "grey",
                },
              ".css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon":
                {
                  color: "grey",
                },
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function CustomTable({ rows, values }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [theme, setTheme] = useState(localStorage.getItem("theme"));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Paper
      sx={{ width: "100%", mb: 8, boxShadow: "none" }}
      className="!bg-sideheadbar-color "
    >
      <TableContainer
    
        className="bg-sideheadbar-color"
        sx={{ maxHeight: "100%" , overflowX: "scroll"}}
      >
        <Table
          size={dense ? "small" : "medium"}
          className="!text-text-color "
          stickyHeader
          aria-label="sticky table"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {visibleRows.map((row, index) => (
              <TableRow
                hover
                onClick={(event) => handleClick(event, row.id)}
                role="checkbox"
                aria-checked={isSelected(row.id)}
                tabIndex={-1}
                key={row.id}
                selected={isSelected(row.id)}
                sx={{
                  cursor: "pointer",
                  "&.Mui-selected": {
                    backgroundColor: "transparent",
                  },
                  "&:hover": {
                    backgroundColor: "transparent !important",
                  },
                }}
              >
                {values.map((val) => (
                  <TableCell
                    key={val.id}
                    className="!text-text-color !border-background-color !border-b-4 !text-[10px] md:!text-xs"
                    align="center"
                  >
                    {row[val.label.toLowerCase()]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell className="!text-text-color " colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter className="absolute bottom-1 right-4 bg-sideheadbar-color text-text-color">
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[50, 100, 250, 500, 1000]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="!text-text-color !text-[10px] md:!text-xs"
            sx={{
              "& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar": {
                justifyContent: "center",
              },
              lineHeight: "0.4px",
              "& .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon": {
                color: `${theme === "dark" ? "white":"black"}`,
              },
              "& .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled":
                {
                  color: `${theme === "dark" ? "white":"black"}`,
                },
              "& .css-pdct74-MuiTablePagination-selectLabel": {
                fontSize: "0.7rem",
              },
              "& .css-levciy-MuiTablePagination-displayedRows": {
                fontSize: "0.7rem",
              },
              "& .css-16c50h-MuiInputBase-root-MuiTablePagination-select": {
                marginRight: "5px",
                marginLeft: "5px",
              },
              "& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions":
                {
                  marginLeft: "3px",
                },
            }}
          />
        </TableRow>
      </TableFooter>
    </Paper>
  );
}
