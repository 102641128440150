import React, { useEffect, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePickerStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { setDate } from "../../redux/selectedOptionsSlice";
import { tr, enUS } from 'date-fns/locale';

// Register the locales
registerLocale('tr', tr);
registerLocale('en', enUS);

const DatePicker = () => {
  const { checkLanguage } = useSelector((store) => store.checkLang);
  const locale = checkLanguage ? 'tr' : 'en';
  const [dateRange, setDateRange] = useState([
    new Date(),
    new Date(),
  ]);
  
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch();

  useEffect(() => {
    if (startDate && endDate) {
      const convertedStartDate = convert(startDate);
      const convertedEndDate = convert(endDate, true);
      dispatch(setDate([convertedStartDate, convertedEndDate]));
    
    }
  }, [dateRange, startDate, endDate, dispatch]);

  function convert(date, isEndOfDay = false) {
    const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const time = isEndOfDay ? " 23:59:59" : " 00:00:00";
    return [date.getFullYear(), mnth, day].join("-") + time;
  }

  return (
    <ReactDatePicker
      dateFormat="dd/MM/yyyy"
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update || [null, null]); // If no date is selected, use null
      }}
      onFocus={(e) => e.target.blur()}
      isClearable={false}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      className="bg-sideheadbar-color min-w-full flex h-10 3xl:!h-16 4xl:!h-20 3xl:text-xl 4xl:text-3xl outline-none text-center px-2 border border-border-color text-text-color rounded-md"
      locale={locale} 
    />
  );
};

export default DatePicker;
